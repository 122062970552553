import React, { useEffect, useState, useContext } from "react";
import "./Leltar.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, set } from "date-fns";
import jwt_decode from "jwt-decode";
import Statisztika from "../Statisztika/Statisztika";
import { ThemeContext } from "../App/ThemeContext";
import { faLocationPin, faCalendar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getItemsFromStorage,
  getScrapItemsFromStorage,
  deleteItemFromStorage,
  getGaranciaStat,
  getQRCodesVoucher
} from "../../Services/Api/StorageApi";

const UsingFetch = () => {
  const { theme } = useContext(ThemeContext);
  const primaryColor = theme.primary;
  const [items, setItems] = useState([]);
  const [cardVoucherItems, setcardVoucherItems] = useState([]);
  const [cardVoucheredItems, setcardVoucheredItems] = useState([]);
  const [warranty, setWarranty] = useState([]);
  const [scrapItems, setscrapItems] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [filter, setFilter] = useState("Összes");
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = (time, endTime) => {
    setIsLoading(true);
    if ((time !== undefined, endTime !== undefined)) {
      getItemsFromStorage(time, endTime).then((data) => {
        if(data.items !== undefined) {
          var decoded;
          try {
            decoded = jwt_decode(localStorage.getItem("accessToken"));
          } catch (error) {
            localStorage.clear();
            window.location = '/login';
            localStorage.setItem('isUserLoggedIn', false);
          }
      if (decoded.priv === 2) {
        setItems(data.items.filter((items) => items.user === decoded.user_id));
        setIsLoading(false);
      } else {
        setItems(data.items);
        setIsLoading(false);
      }
        }
        
    }).then(() => {
      if ((time !== undefined, endTime !== undefined)) {
      
      getQRCodesVoucher(time, endTime).then((data) => {
        console.log(data);
        setcardVoucheredItems(data);
        setIsLoading(false);
    })
  }});
  
    }
  };
  const fetchWarrantyData = (time, endTime) => {
    if ((time !== undefined, endTime !== undefined)) {
      getGaranciaStat(time, endTime).then((data) => {
        if(data.items !== undefined)
          setWarranty(data);
      });
    }
  };
  
  const fetchScrap = (time, endTime) => {
    if ((time !== undefined, endTime !== undefined)) {
      getScrapItemsFromStorage(time, endTime).then((data) => {
        setscrapItems(data.items);
      });
    }
  };

  const addFilter = () => {
    if (localStorage.getItem("isUserLoggedIn") === "true") {
      var decoded;
      try {
        decoded = jwt_decode(localStorage.getItem("accessToken"));
      } catch (error) {
        localStorage.clear();
        window.location = '/login';
        console.error("Invalid token specified", error);
        localStorage.setItem('isUserLoggedIn', false);
      }
      if (decoded.priv === 1 || decoded.priv === 0) {
        return (
          <div>
            <p className="inputLabel">Helyszín</p>
            <select
              className="mobileInput"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            >
              <option value="Összes">Összes</option>
              <option value="Westend">Westend</option>
              <option value="Westend II">Westend II</option>
              <option value="Westend III">Westend III</option>
              <option value="Westend bolt">Westend bolt</option>
              <option value="Andrássy üzlet">Andrássy üzlet</option>
              <option value="Pólus Center">Pólus Center</option>
              <option value="Campona">Campona</option>
              <option value="Webshop">Webshop</option>
            </select>
          </div>
        );
      }
    }
  };
  const setDataAndFetch = (date) => {
    setStartDate(date);
    if (date > endDate) {
      setEndDate(date);
    }
    fetchData(format(date, "yyyy-MM-dd"));
    fetchWarrantyData(format(date, "yyyy-MM-dd"));
  };
  function handleDelete(id) {
    if(window.confirm("Biztosan törölni akarod ezt a bejegyzést?")) {
    deleteItemFromStorage(id).then(() => {
      fetchData(format(startDate, "yyyy-MM-dd"), format(endDate, "yyyy-MM-dd"));
    });
  }
  }

  const setendDateAndFetch = (date) => {
    setEndDate(date);
    if (date < startDate) {
      setStartDate(date);
    }
  };
  const ItemOfPersons = (itemsFiltered) => {
    if (itemsFiltered.length < 400) {
      return (
        <div className="productTable">
          {itemsFiltered.map((val, key) => {
            var allItem = val.items;
            return (
              <div
                key={key}
                className="productRow"
                style={{ borderColor: primaryColor, borderWidth: 3 }}
                onClick={() => handleDelete(val.id, allItem, val.location)}
              >
                <div className="centerDiv">
                  <div className="halfdiv">
                    <div
                      className="location"
                      style={{ backgroundColor: primaryColor }}
                    >
                      <div
                        className="locationIcon"
                        style={{ backgroundColor: "white" }}
                      >
                        <FontAwesomeIcon
                          style={{
                            color: primaryColor,
                            fontSize: "11px",
                            padding: "5px",
                            paddingLeft: "6px",
                          }}
                          icon={faLocationPin}
                        />
                      </div>
                      <div className="locationName">{val.location}</div>
                    </div>
                  </div>
                  <div className="halfdiv timediv">
                    <div
                      className="timetext"
                      style={{ backgroundColor: "white", color: "black" }}
                    >
                      <div
                        className="clockIcon"
                        style={{ backgroundColor: primaryColor }}
                      >
                        <FontAwesomeIcon
                          style={{
                            color: "white",
                            fontSize: "11px",
                            padding: "5px",
                            paddingLeft: "5px",
                          }}
                          icon={faCalendar}
                        />
                      </div>
                      <div className="timeName">{val.time}</div>
                    </div>
                    <p className="timetable"></p>
                  </div>
                  <hr style={{ color: primaryColor, margin: "5px" }}></hr>
                  <div className="financialDiv" style={{ marginBottom: "5px" }}>
                    <p>
                      {formatPrice(val.price)} Ft - {val.paymentType}
                    </p>
                  </div>
                </div>

                {allItem.map((val1, key) => {
                  return (
                    <p className="productType perProd">
                      <b>{val1.itemType}</b>{" "}
                      {val1.mobileType != "-" && "(" + val1.mobileType + ")"}{" "}
                      {val1.itemPrice != undefined &&
                        " - " + val1.itemPrice + " Ft"}
                    </p>
                  );
                })}
                <br></br>
                {val.warranty == 1 && (
                  <p
                    className="warranty"
                    style={{ color: primaryColor, borderColor: primaryColor }}
                  >
                    {" "}
                    Garanciális{" "}
                  </p>
                )}
                {val.warrantyReplace != null && (
                  <p
                    className="warranty"
                    style={{ color: primaryColor, borderColor: primaryColor }}
                  >
                    {" "}
                    Garanciális csere
                  </p>
                )}
                {val.slimmerEmail != null && (
                  <p
                    className="warranty"
                    style={{ color: primaryColor, borderColor: primaryColor }}
                  >
                    {" "}
                    Slimmer
                  </p>
                )}
                <center>
                  <hr
                    style={{
                      color: primaryColor,
                      margin: "5px",
                      width: "20px",
                    }}
                  ></hr>
                </center>
                <p className="userName"> {val.userName} </p>
              </div>
            );
          })}
        </div>
      );
    }
  };
  const formatPrice = (value) => {
    const plainNumber = value.toString().replace(/[\s,]/g, "");
    // Convert the plain number to a numeric value
    const numericValue = parseFloat(plainNumber);
    // Check if the numeric value is a valid number
    if (!isNaN(numericValue)) {
      // Use toLocaleString to format the number with spaces as thousand separators
      return numericValue.toLocaleString("hu");
    } else {
      // Return the original value if it's not a valid number
      return value;
    }
  };
  const createItemList = () => {
    var itemsFiltered = items;
    var decoded;
    try {
      decoded = jwt_decode(localStorage.getItem("accessToken"));
    } catch (error) {
      localStorage.clear();
      window.location = '/login';
      console.error("Invalid token specified", error);
      localStorage.setItem('isUserLoggedIn', false);
    }
      if (decoded.priv === "2") {

        itemsFiltered = items.filter((items) => items.user === decoded.id);
      }
    
    var newScraps = scrapItems;
    var cardVoucheredItemsfiltered = cardVoucherItems;
    if (filter !== "Összes") {
      itemsFiltered = itemsFiltered.filter(
        (items) => items.location === filter
      );
      newScraps = scrapItems.filter((items) => items.location === filter);
      cardVoucheredItemsfiltered = cardVoucherItems.filter(
        (items) => items.location === filter
      );
    }
    return (
      <div>
        <br></br>

        <p className="inputLabel">Kezdődátum</p>
        <DatePicker
          classname="datepicker"
          selected={startDate}
          onChange={(date) => setDataAndFetch(date)}
        />
        <p className="inputLabel">Végedátum</p>
        <DatePicker
          classname="datepicker"
          selected={endDate}
          onChange={(date) => setendDateAndFetch(date)}
        />
        {addFilter()}
        {itemsFiltered.length !== 0 && (
          <Statisztika
            vouchers={cardVoucheredItems}
            parentToChild={itemsFiltered}
            scraps={newScraps}
            startDate={startDate}
            warranty={warranty}
            cardVoucheredItems={cardVoucheredItemsfiltered}
            endDate={endDate}
          />
        )}
        <div className="productTable" id="leltar">
          <h2 style={{ color: primaryColor, marginBottom: "25px" }}>Leltár</h2>
          {itemsFiltered.length !== 0 && ( <a
            href="#statisztika"
            className="gotoBlockLink"
            style={{ borderColor: primaryColor, color: primaryColor }}
          >
            Statisztika
          </a>)}
          {isLoading ? (
            <div className="loader" style={{ borderTopColor: primaryColor }} />
          ) : itemsFiltered.length > 0 ? (
            ItemOfPersons(itemsFiltered)
          ) : (<div className="gotoBlockLink" style={{width: "200px", margin: "auto", marginTop: "15px"}}>Nincs erre az időszakra felvitt eladás</div>)}
          <hr></hr>
        </div>
      </div>
    );
  };
  useEffect(() => {
    fetchData(format(startDate, "yyyy-MM-dd"), format(endDate, "yyyy-MM-dd"));
    fetchWarrantyData(
      format(startDate, "yyyy-MM-dd"),
      format(endDate, "yyyy-MM-dd")
    );
    fetchScrap(format(startDate, "yyyy-MM-dd"), format(endDate, "yyyy-MM-dd"));
    const intervalCall = setInterval(() => {
      fetchData(format(startDate, "yyyy-MM-dd"), format(endDate, "yyyy-MM-dd"));
      fetchWarrantyData(
        format(startDate, "yyyy-MM-dd"),
        format(endDate, "yyyy-MM-dd")
      );
      fetchScrap(
        format(startDate, "yyyy-MM-dd"),
        format(endDate, "yyyy-MM-dd")
      );
    }, 30000);
    return () => {
      // clean up
      clearInterval(intervalCall);
    };
  }, [startDate, endDate]);
  return createItemList();
};
export default UsingFetch;
