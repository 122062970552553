// GuestProfile.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import placeholderImage from '../../img/slimstore_logo.png'
import { getBadges } from '../App/Badges';
import { apiUrl } from '../App/Constants';
import {
  getProfileById,
} from "../../Services/Api/ProfileApi";

function GuestProfile() {
    const [error, setError] = useState('');
    const [profile, setUserProfile] = useState([]);
    const [profilePicture, setProfilePicture] = useState("");
    let { id } = useParams();

    useEffect(() => {
        fetchProfile()
      }, []);

    const fetchProfile = () => {
      getProfileById(id).then(data => {
          setUserProfile(data);
          console.log(data)
          setProfilePicture(data["profile_pic"])
      }).catch(error => {
        setError("Nincs ilyen felhasználó")
      })
      }
      const getJobName = (foundUser) => {
        switch (foundUser.jobrole) {
          case "fulltime":
            return "Teljes munkaidős"
          case "parttime":
            return "Részmunkaidős"
          case "intern":
            return "Betanuló" + " - " + internPhase(foundUser) + ". fázis"
          default:
            return "Inaktív"
        }
      }
      const internPhase = (foundUser) => {
        if(foundUser == null){
          return;
        } else {
        
        const today = new Date();
        const date = new Date(foundUser.created_at);
        const timeDifference = today - date;
        const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
        if (daysDifference < 10) {
        return 1;
      } else if (daysDifference >= 10 && daysDifference < 20) {
        return 2;
      } else {
        return 3;
      }
    }
}
    return (
      <div style={{ textAlign: 'center' }}>
        <h2>{profile ? profile["real_name"] : ""}</h2>
        <p className='jobnametitle'>{profile ? getJobName(profile) : ""}</p>
        
        <hr class="hrc"></hr>
        <div
          style={{
            width: '300px',
            height: '300px',
            borderRadius: '100%',
            border: '3px solid #ddd',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            margin: 'auto',
            backgroundImage: `url(${profilePicture == "" ? placeholderImage : "https://leltar.slimstore.hu/" + "profile_images/" + profilePicture})`,
          }}
        ></div>
      {error && <div style={{ color: 'red', marginTop: '10px' }}>{error}</div>}
      <hr class="hrc"></hr>
       {profile != null && profile.bonuses != null && profile.bonuses.length > 0 && <h3>Eredmények</h3>} 
      
      <div className='medals'>{profile != null ? getBadges(profile) : null}</div>
    </div>
    );
  }

export default GuestProfile;