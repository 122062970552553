import React, { useEffect, useState, useContext } from 'react';
import './New_item.css';
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { ThemeContext } from '../App/ThemeContext';
import { getLocationsAndItemTypes, postNewItemToReplace} from '../../Services/Api/StorageApi';
import {
    removeInventory,
  } from "../../Services/Api/InventoryApi";

export default function New_item() {
    const { theme } = useContext(ThemeContext);
    const primaryColor = theme.primary;
    const [location, setLocation] = useState("");
    const [itemType, setItemType] = useState("");
    const [scrapOrReplace, setScrapOrReplace] = useState("");
    const [itemList] = useState(["Slim HD", "Slim Self-healing", "Slim Privacy Matt", "Slim Pad HD", "Slim Pad Matt", "Slim UV"]);
    const [locationList, setLocationList] = useState([]);
    const [itemData, setItemData] = useState([]);

    const handleSubmit = () => {
        if (localStorage.getItem("isUserLoggedIn") === "true") {
            var decodedUser = jwt_decode(localStorage.getItem("accessToken"));
            if(window.confirm("Biztosan rögzíti a selejt/csere/próba adatokat?") === false) return;
            
            // Az összes típus és cserének elküldése külön-külön
            itemData.forEach(item => {
                var body = {
                    location: location,
                    type: item.itemType,
                    scraporreplace: item.scrapOrReplace,
                    user: decodedUser.id,
                    userName: decodedUser.name,
                  } 
                postNewItemToReplace(body);
                console.log(item.itemType);
                removeInventory(location, item.itemType, "-");
            });
            alert("Selejt/csere/próba rögzítve!");
            setItemData([]);
        } else {
            window.location = '/login'
        }
    }
    const handleItemClick = (indexToRemove) => {
      const updatedItems = itemData.filter((_, index) => index !== indexToRemove);
      setItemData(updatedItems);
  };
  
    const handleAddItem = () => {
        setItemData([...itemData, { itemType, scrapOrReplace }]);
        setItemType("");
        setScrapOrReplace("");
    }

    const fetchData = () => {
        getLocationsAndItemTypes().then(data => {
            setLocationList(data.locations);
        })
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div>
            <h2 style={{color: primaryColor, marginBottom: "25px"}}>Új csere/selejt/próba felvitele</h2>

            <form>
                <p className='inputLabel'>Helyszín</p>
                <select className='mobileInput' value={location} onChange={e => setLocation(e.target.value)} required>
                    <option disabled></option>
                    {locationList.map((val, key) => {
                        return (
                            <option value={val.locationName}>{val.locationName}</option>
                        )
                    })}
                </select>
                <p className='inputLabel'>Típus</p>
                <select className='mobileInput' value={itemType} onChange={e => setItemType(e.target.value)} required>
                    <option disabled></option>
                    {itemList.map((val) => {
                        return (
                            <option value={val}>{val}</option>
                        )
                    })}
                </select>

                <p className='inputLabel'>Csere / Selejt / Próba</p>
                <select className='mobileInput' value={scrapOrReplace} onChange={e => setScrapOrReplace(e.target.value)} required>
                    <option disabled></option>
                    <option value="Csere">Csere</option>
                    <option value="Selejt">Selejt</option>
                    <option value="Próba">Próba</option>
                </select>

                <br></br>
                <button type='button' className='mobileInput submitbutton' onClick={handleAddItem} style={{backgroundColor: primaryColor}}>Hozzáadás</button>
            </form>
            {itemData.length > 0 && (
    <div className='itemDiv' style={{borderColor: primaryColor, width: "200px", borderWidth: "2px"}}>
         <p className='inputLabel' style={{width:"100%"}}>Hozzáadott termékek</p>
        {itemData.map((item, index) => (
            <p className='item' style={{backgroundColor: primaryColor, fontSize: "12px", paddingLeft: "10px", paddingRight: "10px"}} key={index} onClick={() => handleItemClick(index)}>{item.itemType} - {item.scrapOrReplace}</p>
        ))}
    </div>
)}
            {itemData.length > 0 && (
            <button type='button' className='mobileInput submitbutton' onClick={handleSubmit} style={{backgroundColor: primaryColor}}>Az összes felvitele</button>
          )}
        </div>
    );
}
