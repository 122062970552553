import { apiUrl } from '../../Components/App/Constants';
import axios from 'axios';

export async function login(username, password) {
  try {
    const response = await axios.post(apiUrl + 'login/', {
      username: username,
      password: password,
    });

    const { access, refresh } = response.data.access;

    if (access && refresh) {
      localStorage.setItem('accessToken', access);
      localStorage.setItem('refreshToken', refresh);
      localStorage.setItem("isUserLoggedIn", true);
    } else {
      console.error('Tokenek nem találhatóak a válaszban.');
    }

    return response;
  } catch (error) {
    console.error('Login hiba:', error);
    throw error;
  }
}