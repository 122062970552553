import React, { useState, useEffect } from 'react';
import jwt_decode from "jwt-decode";
import axios from 'axios';
import 'reactjs-popup/dist/index.css';
import { apiUrl } from '../App/Constants';
import {
  getItemsFromStorage
} from "../../Services/Api/StorageApi";
import {
  addBonusToUser
} from "../../Services/Api/PromoterApi";

const isMobile = /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
const styles = {
    header: {
      backgroundColor: '#f2f2f2',
      padding: '8px',
      textAlign: 'left',
      fontWeight: 'bold',
      fontSize: '14px',
      textAlign: 'center',
      border: '1px solid #ddd',
    },
    evenRow: {
      backgroundColor: '#f9f9f9',
    },
    oddRow: {
      backgroundColor: '#ffffff',
    },
  };
      
      const rowStylesOrange = {
        fontSize: "15px",
        display: isMobile ? "block" : "flex",
        justifyContent: "space-between",
        padding: "20px",
        borderRadius: "10px",
        margin: "5px",
        alignItems: "center",
        backgroundColor: "#fdcb6e"
      };
      const rowStylesGrey = {
        fontSize: "15px",
        display: isMobile ? "block" : "flex",
        justifyContent: "space-between",
        padding: "20px",
        borderRadius: "10px",
        margin: "5px",
        alignItems: "center",
        backgroundColor: "grey"
      };
      const rowStylesRed = {
        fontSize: "15px",
        display: isMobile ? "block" : "flex",
        justifyContent: "space-between",
        padding: "20px",
        borderRadius: "10px",
        margin: "5px",
        alignItems: "center",
        backgroundColor: "#ff7675"
      };
      const rowStylesGreen = {
        fontSize: "15px",
        display: isMobile ? "block" : "flex",
        justifyContent: "space-between",
        padding: "20px",
        borderRadius: "10px",
        margin: "5px",
        alignItems: "center",
        backgroundColor: "#00b894",
        color: "white"
      };
      
      const columnStyles = {
        flexBasis: "10%",
      };
      const headerStyles = {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
        fontWeight: 'bold',
        padding: '10px',
        color: 'black',
        textAlign: 'center',
        fontSize: '14px'
      };
export default function WeeklyMasters() {
  
  const [data, setData] = useState([
    
  ]);
  
  const [allData, setAllData] = useState([])
  const [isCurrentWeek, setIsCurrentWeek] = useState(false)
  const currentDate = new Date();
  const [currentYear, setCurrentYear] = useState(2024);
  const currentMonth = currentDate.getMonth() + 1;
  const [selectedMonth, setSelectedMonth] = useState(`2024-${currentMonth.toString().padStart(2, '0')}`)
    const [selectedWeek, setSelectedWeek] = useState('');
    const weekList = [];

  useEffect(() => {
    if(selectedWeek != ""){
    const startDateString = selectedWeek.split('(')[1].split('-')[0].replaceAll('.', '-').trim().replaceAll(' ', '');
    const droppedStartDateString = startDateString.substring(0, startDateString.length - 1)
    const endDateString = selectedWeek.split('-')[1].split(')')[0].replaceAll('.', '-').trim().replaceAll(' ', '');
    const droppedEndDateString = endDateString.substring(0, endDateString.length - 1)
    // Mai dátum létrehozása
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();
    today = yyyy + '-' + mm + '-' + dd;
    if (today >= droppedStartDateString && today <= droppedEndDateString) {
      setIsCurrentWeek(true);
      } else {
      setIsCurrentWeek(false);
    }
    fetchData(droppedStartDateString, droppedEndDateString);
    } else {
      setSelectedWeek(weekList[0])
    }
    
}, [selectedWeek]);
// Function to get all unique dates for a user
function getUniqueDates(dataArray) {
    const dateSet = new Set(dataArray.map(item => item.time.split(" ")[0]));
    return Array.from(dateSet);
  }
  // Generate a list of months from January 2023 to the current month
  const monthsList = [];
  for (let year = 2023; year <= currentYear; year++) {
    const maxMonth = year === currentYear ? currentMonth : 12;
    for (let month = 1; month <= maxMonth; month++) {
      const monthValue = month.toString().padStart(2, '0');
      monthsList.push(`${year}-${monthValue}`);
    }
  }
// Kezdeti állapot beállítása


const currentWeekNumber = Math.ceil(
  (currentDate - new Date(currentYear, 0, 1)) / 604800000
);

// Hét lista létrehozása

for (let i = currentWeekNumber; i >= 1; i--) {
  const startDate = new Date(currentYear, 0, (i - 1) * 7 + 1);
  const endDate = new Date(currentYear, 0, i * 7);
  const weekLabel = `${i}.hét (${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()})`;
  weekList.push(weekLabel);
}

// Dropdown változáskezelő
const handleDropdownChange = (event) => {
  setSelectedWeek(event.target.value);
};
const handleYearChange = (event) => {
    setCurrentYear(event.target.value);
  };

// Function to group objects by a given key
function groupBy(array, key) {
    return array.reduce((result, obj) => {
      const keyValue = obj[key];
      (result[keyValue] = result[keyValue] || []).push(obj);
      return result;
    }, {});
  }
const fetchData = (startDate, endDate) => {
  getItemsFromStorage(startDate, endDate).then((response) => {
    console.log(response)
    const groupedArray = groupBy(response.items, "userName");
        delete groupedArray["Slim Store"]
        delete groupedArray["László Emma"]
        delete groupedArray["Zentai Zsófi"]

        // Convert the grouped object into an array of objects
        const newArray = Object.entries(groupedArray).map(([key, value]) => ({ [key]: value }));
        const newArrayof = [];
        const startDate1 = new Date(startDate);
        const weekDays = [];
      
        const startDayOfWeek = startDate1.getDay() -1 ;
      
        for (let i = 0; i < 7; i++) {
          const nextDate = new Date(startDate1);
          nextDate.setDate(startDate1.getDate() + i - startDayOfWeek);
          weekDays.push(nextDate.toISOString().split('T')[0]);
        }
        for (let index = 0; index < newArray.length; index++) {
            const name = Object.keys(newArray[index])[0];
            const values = Object.values(newArray[index])[0];
            const monday = calculateTotalizeForDate(calculateTotalValueByDate(weekDays[0], values), weekDays[0]);
            const tuesday = calculateTotalizeForDate(calculateTotalValueByDate(weekDays[1], values), weekDays[1]);
            const wednesday = calculateTotalizeForDate(calculateTotalValueByDate(weekDays[2], values), weekDays[2]);
            const thursday = calculateTotalizeForDate(calculateTotalValueByDate(weekDays[3], values), weekDays[3]);
            const friday = calculateTotalizeForDate(calculateTotalValueByDate(weekDays[4], values), weekDays[4]);
            const saturday = calculateTotalizeForDate(calculateTotalValueByDate(weekDays[5], values), weekDays[5]);
            const sunday = calculateTotalizeForDate(calculateTotalValueByDate(weekDays[6], values), weekDays[6]);
            const result = monday + tuesday + wednesday + thursday + friday + saturday + sunday;
            var workdays = 0;
            if(monday > 0){
              workdays++;
            }
            if (tuesday > 0){
              workdays++;
            }
            if (wednesday > 0){
              workdays++;
            }
            if (thursday > 0){
              workdays++;
            }
             if (friday > 0){
              workdays++;
            }
            if (saturday > 0){
              workdays++;
            }
            if (sunday > 0){
              workdays++;
            }
            const average = result / workdays;
            newArrayof.push({userid: values[0].user, name: name, Monday: monday, Tuesday: tuesday, Wednesday: wednesday, Thursday: thursday, Friday: friday, Saturday: saturday, Sunday: sunday, result: result.toFixed(1), average: average.toFixed(2) });
        }
            
            setData(newArrayof.sort((a, b) => parseFloat(b.result) - parseFloat(a.result)));
            setAllData(newArray);
  })
    
    
  }
  function calculateTotalValueByDate(startDate, data) {
    return data.reduce((acc, item) => {
        const itemDate = item.time.split('T')[0]; // Csak a dátum kinyerése
        if (itemDate === startDate) {
            return acc + parseInt(item.price || 0);
        }
        return acc;
    }, 0);
  }
  function calculateTotalValue(userData) {
    return userData.reduce((acc, item) => acc + parseInt(item.price), 0);
  }

  function calculateTotalizeForDate(sum, date) {
    var totalize = 0.00;
      if(date < '2023-07-25'){

        totalize += sum/6000
      }
      if(date < '2023-10-10'){

        totalize += sum/7000
      } 
      if (date < '2023-12-01'){
        totalize += sum/8000
      } else {
        if (date < '2024-06-01'){
          totalize += sum/10000
        } else {
          totalize += sum/12000
        }
      }
      return totalize
  }
  function getMaxScoreForDaysWithUserId(data) {
    let maxScore = -Infinity;
    let maxScoreUserId = null; // Kezdeti érték: null
    data.forEach(user => {
        let maxDayScore = Math.max(user.Monday, user.Tuesday, user.Wednesday, user.Thursday, user.Friday, user.Saturday, user.Sunday);
        if (maxDayScore > maxScore) {
            maxScore = maxDayScore;
            maxScoreUserId = user.userid;
        }
    });
    return { userId: maxScoreUserId, maxScore: maxScore };
}
  async function calculateWeeklyTops() {
  let maxScore = getMaxScoreForDaysWithUserId(data);
  console.log(maxScore);
    if (window.confirm('Biztosan lezárod a heti bónuszokat és rekordokat?')) {
      const prizes = {"Heti I.": 20000, "Heti II.": 10000, "Heti III.": 5000};
    // String szétválasztása a zárójelek mentén
    var datesString = selectedWeek.split('(')[1].split(')')[0];
    // Dátumok szétválasztása a kötőjelek mentén és az első dátum kivágása
    var datesArray = datesString.split(' - ');
    // Az első dátum felbontása évre, hónapra, napra
    var firstDateComponents = datesArray[0].split('. ');
    // Az év és hónap kiírása
    var year = firstDateComponents[0];
    var month = firstDateComponents[1];

    const yearmonth = year + "-" + month;
    const selectedWeekNum = selectedWeek.split('.')[0];
    
    var options = {  
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem("accessToken")
      }
    }
    Object.entries(prizes).map(([title, value], index) => {
      const userid = data[index].userid;
      let data = {
        "yearmonth": yearmonth,
        "bonustitle": title,
        "bonusvalue": value,
        "userid": parseInt(userid),
        "week": parseInt(selectedWeekNum)
      }
      addBonusToUser(data).then((response) => {
        console.log(response)
      })
    });
    }
    
  }
  // Function to create day-based summed array
function createSummedArray(dataArray) {
  const summedArray = [];

  dataArray.forEach((item) => {
    const date = item.time.split('T')[0]; // Extract the date from the "time" field
    const price = parseInt(item.price);

    const existingEntry = summedArray.find((entry) => entry.date === date);
    if (existingEntry) {
      existingEntry.sum += price;
    } else {
      summedArray.push({ date, sum: price });
    }
  });

  return summedArray;
}
  // Calculate the totalValue/7000 and sort the allData array
const sortedAllData = allData.map(data => {
  var value = 6000;
  if (selectedMonth < "2023-07") {
    value = 6000
  } else {
    value = 7000
  }
  if (selectedMonth > "2023-10") {
    value = 8000
  }
  if (selectedMonth == "2023-07") {
    value = 6300
  }
  if (selectedMonth == "2023-10") {
    value = 7400
  }
  if (selectedMonth > "2023-11") {
    value = 10000
  }
  if (selectedMonth > "2024-05") {
    value = 10000
  }
    const username = Object.keys(data)[0];
    const userData = data[username];
    const dateSums = createSummedArray(userData);

    var totalize = 0.00;
    dateSums.forEach((item) => {
      if(item.date < '2023-07-25'){

        totalize += item.sum/6000
      }
      if(item.date < '2023-10-10'){

        totalize += item.sum/7000
      } 
      if (item.date < '2023-12-01'){
        totalize += item.sum/8000
      } else {
        if (item.date < '2024-06-01'){
          totalize += item.sum/10000
        } else {
          totalize += item.sum/12000
        }
      }
    })
    const totalValue = calculateTotalValue(userData);
    const totalValueNormalized = parseInt(totalize.toFixed(2));
    const totalValueNormalizedByDates = parseInt((totalValueNormalized / getUniqueDates(userData).length).toFixed(2));
  
    return { username, userData, totalValueNormalized, totalValueNormalizedByDates, dateSums };
  }).sort((a, b) => b.totalValueNormalized - a.totalValueNormalized); // Sort in descending order

  // Helper function to calculate the number based on the formula
function calculateNumber(date, totalValue) {
  const result = date < '2023-07-25' ? totalValue / 6000 : date < '2023-10-10' ? totalValue / 7000 : date < '2023-12-01' ? totalValue / 8000 :  date < '2024-06-01' ? totalValue / 10000 :  totalValue / 12000;
  if (result >= 0 && result < 30) {
    return result * 200;
  } else if (result >= 30 && result < 40) {
    return result * 300;
  } else if (result >= 40 && result < 50) {
    return result * 500;
  } else if (result >= 50 && result < 60) {
    return result * 800;
  } else if (result >= 60 && result < 70) {
    return result * 900;
  } else if (result >= 70 && result < 80) {
    return result * 1000;
  } else if (result >= 80 && result < 90) {
    return result * 1100;
  } else if (result >= 90 && result < 100) {
    return result * 1200;
  } else {
    return result * 1300;
  }
}
  const handlePrint = () => {
    
    window.print();
  };
  var decoded = jwt_decode(localStorage.getItem("accessToken"));
  var isAdmin = decoded.priv == 1;
  return(
    // Rendering the sorted table-like layout
<div id='masters'>
    <div>
        <a href='/masters' className='mastersButton no-print' >Havi Masters</a>
        <h2 className='no-print'>Heti Master</h2>
        {!isCurrentWeek && isAdmin && (<button className='no-print' onClick={calculateWeeklyTops} style={{ marginBottom: '10px' }}>Heti bónuszok és rekordok lezárása</button>)}
        <h3 className='only-print'>{selectedWeek}</h3>
        <select value={currentYear} onChange={handleYearChange} className='no-print'>
        <option value={2023}>2023</option>
              <option value={2024}>2024</option>
            </select>
            <br></br>
        <select value={selectedWeek} onChange={handleDropdownChange} className='no-print'>
              <option value="">Válassz hetet...</option>
              {weekList.map((week, index) => (
                <option key={index} value={week}>
                  {week}
                </option>
              ))}
            </select>
            <br></br>
            <button className='no-print' onClick={handlePrint} style={{ marginBottom: '10px' }}>Táblázat Nyomtatása</button>
            </div>

      <table style={{ borderCollapse: 'collapse', width: '100%' }}>
      <thead>
        <tr>
        <th style={styles.header}></th>
          <th style={styles.header}>Név</th>
          <th style={styles.header}>Hétfő</th>
          <th style={styles.header}>Kedd</th>
          <th style={styles.header}>Szerda</th>
          <th style={styles.header}>Csütörtök</th>
          <th style={styles.header}>Péntek</th>
          <th style={styles.header}>Szombat</th>
          <th style={styles.header}>Vasárnap</th>
          <th style={styles.header}>Eredmény</th>
          <th style={styles.header}>Átlag</th>
        </tr>
      </thead>
      <tbody>
        {data.map((person, index) => (
          <tr key={index} style={index % 2 === 0 ? styles.evenRow : styles.oddRow}>
            <td>{index+1}</td>
            <td>{person.name}</td>
            <td style={{fontWeight: person.Monday == 0 ? 'normal' : 800}}>{person.Monday == 0 ? "❌" : person.Monday}</td>
            <td style={{fontWeight: person.Tuesday == 0 ? 'normal' : 800}}>{person.Tuesday == 0 ? "❌" : person.Tuesday}</td>
            <td style={{fontWeight: person.Wednesday == 0 ? 'normal' : 800}}>{person.Wednesday == 0 ? "❌" : person.Wednesday}</td>
            <td style={{fontWeight: person.Thursday == 0 ? 'normal' : 800}}>{person.Thursday == 0 ? "❌" : person.Thursday}</td>
            <td style={{fontWeight: person.Friday == 0 ? 'normal' : 800}}>{person.Friday == 0 ? "❌" : person.Friday}</td>
            <td style={{fontWeight: person.Saturday == 0 ? 'normal' : 800}}>{person.Saturday == 0 ? "❌" : person.Saturday}</td>
            <td style={{fontWeight: person.Sunday == 0 ? 'normal' : 800}}>{person.Sunday == 0 ? "❌" : person.Sunday}</td>
            <td>{person.result}</td>
            <td>{person.average}</td>
          </tr>
        ))}
      </tbody>
      <thead>
        <tr>
        <th style={styles.header}></th>
          <th style={styles.header}>Összesen</th>
          <th style={styles.header}>{data.reduce((accumulator, currentValue) => accumulator + currentValue.Monday, 0)} db</th>
          <th style={styles.header}>{data.reduce((accumulator, currentValue) => accumulator + currentValue.Tuesday, 0)} db</th>
          <th style={styles.header}>{data.reduce((accumulator, currentValue) => accumulator + currentValue.Wednesday, 0)} db</th>
          <th style={styles.header}>{data.reduce((accumulator, currentValue) => accumulator + currentValue.Thursday, 0)} db</th>
          <th style={styles.header}>{data.reduce((accumulator, currentValue) => accumulator + currentValue.Friday, 0)} db</th>
          <th style={styles.header}>{data.reduce((accumulator, currentValue) => accumulator + currentValue.Saturday, 0)} db</th>
          <th style={styles.header}>{data.reduce((accumulator, currentValue) => accumulator + currentValue.Sunday, 0)} db</th>
          <th style={styles.header}>{data.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.result), 0).toFixed(2)} db</th>
          <th style={styles.header}>{(data.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.result), 0)/7).toFixed(2)} db</th>
        </tr>
      </thead>
    </table>
</div>
  )

}


