import { NetworkManager } from '../NetworkManager';
import { apiUrl } from '../../Components/App/Constants';

const apiManager = new NetworkManager(apiUrl);

//PROMOTEREK KEZDETE//
// API lekérdezés a promoterekhez
export async function getEveryPromoter() {
  try {
    const response = await apiManager.get('/promoter/');
    if (response.status === 200) {
        return response.data;
    } else {
      console.error('Unexpected response:', response);
    }
  } catch (error) {
    console.error('Hiba felvitel hiba:', error);
  }
}

// API patch a promoterekhez eltávolításához
export async function removePromoter(id) {
  try {
    const response = await apiManager.patch('/promoter/'+id+'/', {"removeID": id});
    if (response.status === 200) {
        return response.data;
    } else {
      console.error('Unexpected response:', response);
    }
  } catch (error) {
    console.error('Hiba felvitel hiba:', error);
  }
}

// API patch a promoterekhez csapathoz adásához
export async function assignPromoterToTeam(promoters, team) {
  try {
    const response = await apiManager.patch('/promoter/69/', {"promoters": promoters, "teamid": team});
    if (response.status === 200) {
        return response.data;
    } else {
      console.error('Unexpected response:', response);
    }
  } catch (error) {
    console.error('Hiba felvitel hiba:', error);
  }
}

// API post a promoterekhez bonuszának adásához
export async function addBonusToUser(data) {
  try {
    const response = await apiManager.post('/promoterbonus/', data);
    if (response.status === 201) {
        return response.data;
    } else {
      console.error('Unexpected response:', response);
    }
  } catch (error) {
    console.error('Hiba felvitel hiba:', error);
  }
}

// API lekérdezés a bónuszokhoz
export async function getBonusforMonth(month) {
  try {
    const response = await apiManager.get('/promoterbonus/?yearmonth=' + month + '');
    if (response.status === 200) {
        return response.data;
    } else {
      console.error('Unexpected response:', response);
    }
  } catch (error) {
    console.error('Hiba felvitel hiba:', error);
  }
}
//PROMOTEREK VÉGE//