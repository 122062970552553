import React, { useState, useContext } from 'react';
import './Login.css';
import logo from '../../img/slimstore_logo.png'
import packageJson from '../../../package.json';
import { ThemeContext } from '../App/ThemeContext';
import { login } from '../../Services/Api/UserApi';

export default function Login() {
  const { theme } = useContext(ThemeContext);
  const primaryColor = theme.primary;
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [errorMessage, setErrorMessage] = useState('');

 const divStyle = {
  /* További stílusok */
  height: '50%',
  marginBottom: '100px',
};
  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const response = await login(username, password);

      if (response) {
        window.location.href = '/leltar';
      }
    } catch (error) {
      console.error('Login error:', error);
      setErrorMessage('Login failed. Please check your credentials.');
    }
    }

  return(

    <div style={{height: "100vh"}}>

      <div style={divStyle} className='backgroundImage wave-container'><img src={logo} className='logo'></img></div>
      <form onSubmit={handleSubmit}>
      {errorMessage && (
              <p style={{ color: 'red', marginBottom: '16px' }}>
              {errorMessage}
            </p>
            )}
      <input
        className='mobileInput'
        placeholder='felhasználónév'
        type="text"
        value={username}
        onChange={e => setUserName(e.target.value)}
      />
      <input
        className='mobileInput'
        placeholder='jelszó'
        type="password"
        value={password}
        onChange={e => setPassword(e.target.value)}
      />
      <button className='mobileInput submitbutton' type="submit" style={{backgroundColor: primaryColor}}>bejelentkezés</button>
      <p className='version' style={{backgroundColor: primaryColor}}>v{packageJson.version}</p>
    </form>
    </div>

  )

}

