import { NetworkManager } from '../NetworkManager';
import { apiUrl } from '../../Components/App/Constants';

const apiManager = new NetworkManager(apiUrl);

//LOKÁCIÓK ÉS TERMÉKTÍPUSOK KEZDETE//
// API lekérdezés a lokációkhoz és terméktípusokhoz
export async function getLocationsAndItemTypes() {
  try {
    const response = await apiManager.get('/newlist/');

    const { itemTypes, locations } = response.data;

    if (itemTypes && locations) {
        return response.data;
    } else {
      console.error('Ne nem találhatóak a válaszban.');
    }
  } catch (error) {
    console.error('Lokáció és terméktípus lekérés hiba:', error);
    throw error;
  }
}
//LOKÁCIÓK ÉS TERMÉKTÍPUSOK VÉGE//

//QR KÓD KEZDETE//
// API lekérdezés a qr kódokhoz
export async function getQrCodes() {
  try {
    const response = await apiManager.get('/qrcode/');
    if (response.status === 200) {

        return response.data;
    } else {
      console.error('Unexpected response:', response);
    }
  } catch (error) {
    console.error('Hiba felvitel hiba:', error);
  }
}

// API hívás a qr kódokhoz
export async function postQRcode(data) {
    try {
      const response = await apiManager.post('/cardQR/', data);
      if (response.status === 201) {
          return response.data;
      } else {
        console.error('Unexpected response:', response);
      }
    } catch (error) {
      console.error('Hiba felvitel hiba:', error);
    }
  }

  // API hívás a qr kódok lekéréséhez
export async function getQRCodesVoucher(startDate, endDate) {
  try {
    const response = await apiManager.get('/cardQR/?startDate=' + startDate + '&endDate=' + endDate);
    if (response.status === 200) {
        return response.data;
    } else {
      console.error('Unexpected response:', response);
    }
  } catch (error) {
    console.error('Hiba felvitel hiba:', error);
  }
}
//STORAGE KEZDETE//
//Felvitel a Storage táblába
export async function postNewItemToStorage(json, onSuccess) {
    try {
      const response = await apiManager.post('/storage/', json);
      if (response.status === 201) {
          onSuccess();
      } else {
        console.error('Unexpected response:', response);
      }
    } catch (error) {
      console.error('Hiba felvitel hiba:', error);
    }
  }

  //Felvitel a Replace táblába
  export async function postNewItemToReplace(json, onSuccess) {
    try {
      const response = await apiManager.post('/replace/', json);
    console.log(response)
      if (response.status === 201) {
          onSuccess();
      } else {
        console.error('Unexpected response:', response);
      }
    } catch (error) {
      console.error('Hiba felvitel hiba:', error);
    }
  }
  //Lekérdezés a Storage táblából
  export async function getItemsFromStorage(fromDate, toDate) {
    try {
      const response = await apiManager.get('/storage/?date=' + fromDate + '&endDate=' + toDate);
      if (response.status === 200) {
          return response.data;
      } else {
        console.error('Unexpected response:', response);
      }
    } catch (error) {
      console.error('Hiba felvitel hiba:', error);
    }
  }
    //Lekérdezés a Storage táblából Mastersből
    export async function getItemsFromStorageToMasters(month) {
      try {
        const response = await apiManager.get('/storage/?isMasters=true&month=' + month);
        if (response.status === 200) {
            return response.data;
        } else {
          console.error('Unexpected response:', response);
        }
      } catch (error) {
        console.error('Hiba felvitel hiba:', error);
      }
    }
  //Lekérdezés a GaranciaStat táblából
  export async function getGaranciaStat(fromDate, toDate) {
    try {
      const response = await apiManager.get('/garanciastat/?date=' + fromDate + '&endDate=' + toDate);
      if (response.status === 200) {
          return response.data;
      } else {
        console.error('Unexpected response:', response);
      }
    } catch (error) {
      console.error('Hiba felvitel hiba:', error);
    }
  }
  //Masters lekérdezés a Storage táblából
  export async function getItemsFromStorageOnMasters(json, onSuccess) {
    try {
      const response = await apiManager.post('/storage/', json);
      if (response.status === 201) {
          onSuccess();
      } else {
        console.error('Unexpected response:', response);
      }
    } catch (error) {
      console.error('Hiba felvitel hiba:', error);
    }
  }

  //Storageből törlés
    export async function deleteItemFromStorage(id, onSuccess) {
        try {
        const response = await apiManager.delete('/storage/' + id + '/');
        if (response.status === 204) {
            onSuccess();
        } else {
            console.error('Unexpected response:', response);
        }
        } catch (error) {
        console.error('Hiba törlés hiba:', error);
        }
    }
  //STORAGE VÉGE//

  //SCRAP RÉSZ KEZDETE//
  //Scrap lekérdezés a Storage táblából
  export async function getScrapItemsFromStorage(fromDate, toDate) {
    try {
      const response = await apiManager.get('/replace/?date=' + fromDate + '&endDate=' + toDate);
      if (response.status === 200) {
          return response.data;
      } else {
        console.error('Unexpected response:', response);
      }
    } catch (error) {
      console.error('Hiba felvitel hiba:', error);
    }
  }

  //Scrap felvitele a Storage táblából
  export async function postScrapItemsFromStorage(fromDate, toDate) {
    try {
      const response = await apiManager.get('/replace/?date=' + fromDate + '&endDate=' + toDate);
      console.log(response)
      if (response.status === 200) {
          return response.data;
      } else {
        console.error('Unexpected response:', response);
      }
    } catch (error) {
      console.error('Hiba felvitel hiba:', error);
    }
  }
  //SCRAP RÉSZ VÉGE//