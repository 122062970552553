import { NetworkManager } from '../NetworkManager';
import { apiUrl } from '../../Components/App/Constants';

const apiManager = new NetworkManager(apiUrl);

//GARANCIA KEZDETE//
// API lekérdezés a garanciákhoz
export async function getWarranties() {
  try {
    const response = await apiManager.get('/garancia/');

    if (response.data) {
        return response.data;
    } else {
      console.error('Ne nem találhatóak a válaszban.');
    }
  } catch (error) {
    console.error('Lokáció és terméktípus lekérés hiba:', error);
    throw error;
  }
}

// API hívás a garancia hozzáadáshoz
export async function postWarranty(data) {
    try {
      const response = await apiManager.post('/garancia/', data, {
        headers: {
            // A Content-Type fejlécet itt hagyd ki, így a böngésző állítja be automatikusan
        },
    });
  
      if (response.data) {
          return response.data;
      } else {
        console.error('Ne nem találhatóak a válaszban.');
      }
    } catch (error) {
      console.error('Lokáció és terméktípus lekérés hiba:', error);
      throw error;
    }
  }
//GARANCIA VÉGE//