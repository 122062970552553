import React, { useState } from 'react';
import './promoter_reg.css';
import { addProfile } from '../../Services/Api/ProfileApi';

export default function AddPromoter() {
    const [userName, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [jobrole, setJobrole] = useState("fulltime");

    const handleSubmit = (event) => {
        event.preventDefault();
        addProfile(userName, password, jobrole).then(() => {
            alert("Promoter hozzáadva")
            window.location.reload();
        });
    }
    return (
        <div className='containerbox'>
            <form onSubmit={handleSubmit}>
                <input
                    className='mobileInput'
                    type="text"
                    placeholder='Felhasználónév'
                    required
                    value={userName}
                    onChange={input => setUsername(input.target.value)}></input>
                    <br></br>
                <input
                    className='mobileInput'
                    type="password"
                    placeholder='Jelszó'
                    required
                    value={password}
                    onChange={input => setPassword(input.target.value)}></input>
                    <br></br>
                <select className='mobileInput' value={jobrole} onChange={e => setJobrole(e.target.value)} required>
            <option value="fulltime">Teljes állás</option>
            <option value="parttime">Részmunkaidős</option>
            <option value="intern">Betanuló</option>
            </select>
            <br></br>
                <button
                    type='submit'
                    className='mobileInput submitbutton'>Hozzáadás</button>
            </form>
        </div>
    )
}