// ImageUploadComponent.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import placeholderImage from '../../img/slimstore_logo.png'
import jwt_decode from "jwt-decode";
import { getBadges } from '../App/Badges';
import { apiUrl } from '../App/Constants';
import {
  getProfileById,
} from "../../Services/Api/ProfileApi";

function ImageUploadComponent() {
    const [selectedImage, setSelectedImage] = useState('');
    const [error, setError] = useState('');
    const [profile, setUserProfile] = useState([]);
    const [profilePicture, setProfilePicture] = useState("");

    useEffect(() => {
        fetchProfile()
      }, [selectedImage]);

    const fetchProfile = () => {

      var decoded = jwt_decode(localStorage.getItem("accessToken"));
      getProfileById(decoded.id).then(data => {
        if (data) {
          setUserProfile(data);
          setProfilePicture(data["profile_pic"])
        } else {
          setError("Nincs ilyen felhasználó")
        }
      }).catch(error => {
        setError("Nincs ilyen felhasználó")
      })
        
        
      }
    const handleFileChange = (event) => {
      const file = event.target.files[0];
  
      // Check if the selected file is either PNG or JPG
      const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
      if (!allowedTypes.includes(file.type)) {
        setError('Please select a PNG or JPG image.');
        setSelectedImage('');
        return;
      }
  
      setError('');
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    };
  
    const handleUpload = async () => {
      try {
        var decoded = jwt_decode(localStorage.getItem("accessToken"));
        // Make a POST request to the PHP endpoint
        await axios.post(apiUrl + 'upload.php?userId='+ decoded.id, { image: selectedImage });
        console.log('Image uploaded successfully');
        setSelectedImage('');
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    };
  
    return (
      <div style={{ textAlign: 'center' }}>
        <h2>{profile.length > 0 ? profile[0]["real_name"] : ""}</h2>
        <hr class="hrc"></hr>
      <label htmlFor="imageInput" style={{ cursor: 'pointer' }}>
        <div
          style={{
            width: '300px',
            height: '300px',
            borderRadius: '100%',
            border: '3px solid #ddd',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            margin: 'auto',
            backgroundImage: `url(${selectedImage ? selectedImage : profilePicture == "" ? placeholderImage : apiUrl + "profile_images/" + profilePicture})`,
          }}
        ></div>
        <input
          type="file"
          id="imageInput"
          accept=".png, .jpg, .jpeg"
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
      </label>
      {error && <div style={{ color: 'red', marginTop: '10px' }}>{error}</div>}
      <button onClick={handleUpload} style={{ marginTop: '10px' }} disabled={!selectedImage}>
        Profilkép mentése
      </button>
      {profile[0] != null && profile[0].bonuses.length > 0 && <h3>Eredmények</h3>}
      <br></br>
      <div className='medals'>{profile[0] != null ? getBadges(profile[0]) : null}</div>
    </div>
    );
  }

export default ImageUploadComponent;