import axios from 'axios';
import jwtDecode from 'jwt-decode';

export class NetworkManager {
  api;
  refreshApi;

  constructor(baseURL) {
    this.api = axios.create({
      baseURL,
    });

    this.refreshApi = axios.create({
      baseURL,
    });

    this.api.interceptors.request.use(
      async (config) => {
        let accessToken = localStorage.getItem('accessToken');
        const tokenExpiry = accessToken
          ? jwtDecode(accessToken).exp * 1000
          : null;
        if (accessToken === null) {
          localStorage.removeItem('accessToken');
          localStorage.removeItem('refreshToken');
          localStorage.setItem("isUserLoggedIn", false);
          localStorage.removeItem('name');
          window.location.href = '/login'; // Redirect to home or login page
        }
        if (tokenExpiry && Date.now() >= tokenExpiry) {
            accessToken = await this.refreshAccessToken();
        }

        if (accessToken) {
          config.headers['Authorization'] = `Bearer ${accessToken}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  async post(url, data) {
    return this.api.post(url, data);
  }

  async patch(url, data) {
    return this.api.patch(url, data);
  }

  async get(url) {
    return this.api.get(url);
  }

  async delete(url) {
    return this.api.delete(url);
  }

  async refreshAccessToken() {
    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken) {
      console.error('Nincs refresh token.');
      throw new Error('Nincs refresh token');
    }

    try {
      const response = await this.refreshApi.post('token/', { refresh: refreshToken });
      console.log(response)
      if (response.data.access != null) {
         localStorage.setItem('accessToken', response.data.access);
         return response.data.access;
      } else {
        window.location.href = '/login';
      }
    } catch (error) {
      window.location.href = '/login';
      throw error;
    }
  }
}