import { NetworkManager } from '../NetworkManager';
import { apiUrl } from '../../Components/App/Constants';

const apiManager = new NetworkManager(apiUrl);
// API lekérdezés a lokációkhoz
export const fetchLocations = async () => {
  const response = await apiManager.get(apiUrl + 'newlist/');
   return response.data.locations;
};

// API lekérdezés egy adott lokáció készletéhez
export const fetchInventoryByLocation = async (locationId) => {
  const response = await apiManager.get(apiUrl + 'inventory/?location_id=' + locationId);
  return response.data;
};

// API hívás a raktárkészlet frissítéséhez Irodánál
export const updateInventory = async (locationId, updates) => {
  await apiManager.post(apiUrl + 'inventory/', { location_id: locationId, updates: updates });
};

// API hívás a raktárkészletből levonáshoz
export const removeInventory = async (location, itemType, phoneType) => {
  await apiManager.post(apiUrl + 'remove_inventory/', { location: location, itemName: itemType, phoneType: phoneType });
};

// API hívás a raktárkészletből levonáshoz
export const addInventory = async (location, itemType, phoneType) => {
  await apiManager.post(apiUrl + 'add_inventory/', { location: location, itemName: itemType, phoneType: phoneType });
};

// API lekérdezés a globális szükséges készlethez (item_id és subtype szerint)
export const fetchRequiredQuantities = async (locationId) => {
  const response = await apiManager.get(apiUrl + 'required_quantites/?location_id=' +locationId );
  return response.data;
};

// API hívás a globális szükséges készlet frissítéséhez (item_id és subtype szerint)
export const updateRequiredQuantities = async (requiredQuantities, locationId) => {
  await apiManager.post(apiUrl + 'required_quantites/', { requiredQuantities: requiredQuantities, location_id: locationId});
};