import axios from "axios";
import { franchiseUrl } from "../../Components/App/Constants";

// API lekérdezés egy adott branch_id készletéhez
export const fetchInventoryByBranch = async (branchId) => {
  const response = await axios.get(`${franchiseUrl}inventory/inventory.php?branch_id=${branchId}`);
  return response.data.data;
};

// API hívás a készlet és in_transit frissítéséhez
export const updateInventory = async (branchId, inventory) => {
  await axios.post(`${franchiseUrl}inventory/inventory.php`, {
    branch_id: branchId,
    inventory: inventory,
  });
};

// API lekérdezés a szükséges készlethez egy adott branch_id esetén
export const fetchRequiredQuantities = async (branchId) => {
  const response = await axios.get(`${franchiseUrl}inventory/inventory.php?branch_id=${branchId}&required=true`);
  return response.data.required_quantities;
};

// API hívás a szükséges készlet frissítéséhez
export const updateRequiredQuantities = async (requiredQuantities, branchId) => {
  await axios.post(`${franchiseUrl}inventory/inventory.php`, {
    branch_id: branchId,
    required_quantities: requiredQuantities,
  });
};