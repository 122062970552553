import React, { useState, useEffect } from "react";
import { Scanner } from "@yudiel/react-qr-scanner";
import {
  fetchLocations,
  fetchInventoryByLocation,
  updateInventory,
  fetchRequiredQuantities,
  updateRequiredQuantities,
} from "../../Services/Api/InventoryApi";

import "./Raktarkeszlet.css"; // Külső CSS fájl a stílusokhoz

export default function Raktarkeszlet() {
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [inventory, setInventory] = useState([]);
  const [isIroda, setIsIroda] = useState(false);
  const [updates, setUpdates] = useState({});
  const [requiredQuantities, setRequiredQuantities] = useState({}); // Globális szükséges készletek tárolása
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [productData, setProductData] = useState(null);
  const [neededQuantity, setNeededQuantity] = useState(0);
  const [showScanner, setShowScanner] = useState(false);
  const [requiredQuantitiesisOn, setRequiredQuantitiesisOn] = useState(false);

  useEffect(() => {
    const loadLocations = async () => {
      const locationData = await fetchLocations();
      setLocations(locationData);
    };
    loadLocations();
  }, []);

  useEffect(() => {
    setShowScanner(false);
    const loadInventory = async () => {
      if (selectedLocation) {
        console.log(selectedLocation);
        const inventoryData = await fetchInventoryByLocation(selectedLocation);
        setInventory(inventoryData);
        setIsIroda(selectedLocation === "9"); // "Iroda" ID = 9
        const loadRequiredQuantities = async () => {
          const requiredQuantitiesData = await fetchRequiredQuantities(
            selectedLocation
          ); // Fetch globális szükséges készlet
          setRequiredQuantities(requiredQuantitiesData);
        };
        loadRequiredQuantities();
      }
    };
    loadInventory();
  }, [selectedLocation]);

  useEffect(() => {
    
  }, []);

  const handleInventoryChange = (itemId, subtype, value) => {
    setUpdates((prev) => ({
      ...prev,
      [`${itemId}-${subtype}`]: value,
    }));
  };

  const handleRequiredQuantityChange = (itemId, subtype, value) => {
    setRequiredQuantities((prev) => ({
      ...prev,
      [itemId]: {
        ...prev[itemId],
        [subtype]: value,
      },
    }));
  };

  const handleSubmitRequiredQuantities = async () => {
    await updateRequiredQuantities(requiredQuantities, selectedLocation);
    alert("Szükséges készlet frissítve");
  };

  const handleSubmitInventory = async () => {
    await updateInventory(selectedLocation, updates);
    alert("Raktárkészlet frissítve");
  };

  const handleSubmitQR = async () => {
    // Ellenőrizzük, hogy van-e productData és szükséges mennyiség
    if (productData && neededQuantity > 0) {
      // Feltöltés a szükséges mennyiség alapján
      await updateInventory(selectedLocation, {
        [`${productData.item_id}-${productData.phonetype}`]: neededQuantity,
      });

      // Felugró ablak bezárása és raktárkészlet frissítése
      setIsPopupOpen(false);
      const inventoryData = await fetchInventoryByLocation(selectedLocation);
      setInventory(inventoryData);
      setShowScanner(true);
    } else {
      alert("A készlet már rendben van, nincs szükség feltöltésre.");
    }
  };

  const handleScan = (data) => {
    if (data) {
      const [productName, subtype] = data.includes("-")
        ? data.split(" - ")
        : [data, ""];

      const foundProduct = inventory.find(
        (item) =>
          item.item_name === productName &&
          (subtype === "" || item.phonetype === subtype)
      );

      if (foundProduct) {
        setProductData(foundProduct);

        // Számoljuk ki a hiányzó mennyiséget a requiredQuantities alapján
        const requiredQuantity =
          requiredQuantities[foundProduct.item_id]?.[foundProduct.phonetype] ||
          0;
        const missingQuantity = requiredQuantity - foundProduct.quantity;

        setNeededQuantity(missingQuantity > 0 ? missingQuantity : 0);

        setShowScanner(false);
        setIsPopupOpen(true);
      } else {
        alert("Termék nem található a listában.");
      }
    }
  };

  return (
    <div className="containerok">
      <h1>
        {requiredQuantitiesisOn
          ? "Szükséges készlet kezelés"
          : "Raktárkészlet kezelés"}
      </h1>
      <button
        onClick={() => setRequiredQuantitiesisOn(!requiredQuantitiesisOn)}
        className="qr-button"
      >
        {!requiredQuantitiesisOn ? "Szükséges készlet" : "Raktárkészlet"}
      </button>
      <div className="location-select-container">
        <select
          value={selectedLocation}
          onChange={(e) => setSelectedLocation(e.target.value)}
          className="location-select"
        >
          <option value="">Válassz egy helyszínt</option>
          {requiredQuantitiesisOn
            ? locations
                .filter((location) => location.id !== "9")
                .map((location) => (
                  <option key={location.id} value={location.id}>
                    {location.locationName}
                  </option>
                ))
            : locations.map((location) => (
                <option key={location.id} value={location.id}>
                  {location.locationName}
                </option>
              ))}
        </select>
      </div>

      {!isIroda && selectedLocation !== "" && !requiredQuantitiesisOn && (
        <button
          onClick={() => setShowScanner((prev) => !prev)}
          className="qr-button"
        >
          {showScanner ? "QR-kód olvasó elrejtése" : "QR-kód olvasó"}
        </button>
      )}

      {showScanner && !isIroda && (
        <div className="qr-reader">
          <Scanner
            legacyMode={true}
            onScan={(result) => handleScan(result?.[0]?.rawValue)}
            onError={(error) => window.alert("Hibás QR kód")}
            constraints={{ facingMode: "environment" }}
            style={{ width: "100%" }}
          />
        </div>
      )}

      {isPopupOpen && productData && (
        <div className="popup">
          <h2>
            {productData.item_name}{" "}
            {productData.phonetype && ` - ${productData.phonetype}`}
          </h2>
          <p>Aktuális készlet: {productData.quantity}</p>
          <p>
            {neededQuantity > 0
              ? `${neededQuantity} db-ot kell feltölteni`
              : "A készlet rendben van."}
          </p>

          <button onClick={handleSubmitQR}>Kész</button>
          <button onClick={() => setIsPopupOpen(false)}>Mégse</button>
        </div>
      )}

      {inventory.length > 0 && (
        <div className="inventory-table-container">
          <table className="inventory-table">
            <thead>
              <tr>
                <th>Termék</th>
                <th>Típus</th>
                <th>Készlet</th>
                {requiredQuantitiesisOn && selectedLocation != "" && (
                  <th>Szükséges készlet</th>
                )}
                {!requiredQuantitiesisOn &&
                  (selectedLocation !== "" && isIroda ? (
                    <th>Készlet</th>
                  ) : (
                    <th>Hiányzó Mennyiség</th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {inventory.map((item) => {
                const requiredQuantity =
                  requiredQuantities[item.item_id]?.[item.phonetype] || 0;
                const missingQuantity = requiredQuantity - item.quantity;

                // Stílus a háttérszínekhez: piros, ha hiányzik, zöld, ha készlet rendben
                const tdStyle = {
                  backgroundColor:
                    missingQuantity > 0
                      ? "rgba(255, 99, 132, 0.2)"
                      : "rgba(75, 192, 192, 0.2)", // Pasztell piros/zöld
                  color: missingQuantity > 0 ? "red" : "green",
                  padding: "10px",
                  borderRadius: "4px",
                };

                return (
                  <tr key={`${item.item_id}-${item.phonetype}`}>
                    <td>{item.item_name}</td>
                    <td>{item.phonetype !== "-" ? item.phonetype : ""}</td>
                    <td>{item.quantity} db</td>

                    {/* Szükséges készlet mező csak az "Iroda" esetében */}
                    {requiredQuantitiesisOn && selectedLocation != "" && (
                      <td>
                        <input
                          type="number"
                          value={
                            requiredQuantities[item.item_id]?.[
                              item.phonetype
                            ] || 0
                          }
                          onChange={(e) =>
                            handleRequiredQuantityChange(
                              item.item_id,
                              item.phonetype,
                              e.target.value
                            )
                          }
                          className="input-field"
                        />
                      </td>
                    )}

                    {/* Hiányzó mennyiség színezett háttérrel, ha nem Iroda */}
                    {!isIroda && !requiredQuantitiesisOn && (
                      <td style={tdStyle}>
                        {missingQuantity > 0
                          ? `${missingQuantity} db hiányzik`
                          : "Készlet rendben"}
                      </td>
                    )}

                    {/* Új készlet megadása csak az Iroda esetében */}
                    {isIroda && (
                      <td>
                        <input
                          type="number"
                          defaultValue={item.quantity}
                          onChange={(e) =>
                            handleInventoryChange(
                              item.item_id,
                              item.phonetype,
                              e.target.value
                            )
                          }
                          className="input-field"
                        />
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
      {requiredQuantitiesisOn && selectedLocation != "" && (
        <button
          onClick={handleSubmitRequiredQuantities}
          className="submit-button"
        >
          Szükséges készlet frissítése
        </button>
      )}

      {isIroda && (
        <button onClick={handleSubmitInventory} className="submit-button">
          Készlet frissítése
        </button>
      )}
    </div>
  );
}
