import React, {useContext} from 'react';
import '../App/Navbar.css';
import Form from './GaranciaForm';
import List from './GaranciaList';
import jwt_decode from "jwt-decode";
import { ThemeContext } from '../App/ThemeContext';

function Garancia() {

  const { theme } = useContext(ThemeContext);
  const primaryColor = theme.primary;
  if (localStorage.getItem("isUserLoggedIn") === "true") {
    var decodedUser = jwt_decode(localStorage.getItem("accessToken"));
  return (
    <div className="App">
      <h2 style={{color: primaryColor, marginBottom: "25px"}}>Garancia</h2>
      <Form />
      {((decodedUser.priv === 1) || (decodedUser.priv === 0)) && (<List />)}
    </div>
  );}}

export default Garancia;
