import React, { useState, useEffect } from 'react';
import 'reactjs-popup/dist/index.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import jwt_decode from "jwt-decode";
import imageCompression from 'browser-image-compression';
import { format } from 'date-fns'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { apiUrl } from '../App/Constants';
import { getElszamolasbyDate, postElszamolas } from '../../Services/Api/ElszamolasApi';



export default function Elszamolas() {
    const [items, setItems] = useState([])
    const [editedRows, setEditedRows] = useState([])
  const [date, setDate] = useState(new Date())
  const [image1, setImage1] = useState(null);
  const [image2, setImage2] = useState(null);
  const [isImage2, setIsImage2] = useState(false);

  useEffect(() => {
    fetchData(format(date, "yyyy-MM-dd"))
}, []);
const setDataAndFetch = date => {
    setDate(date)
    fetchData(format(date, "yyyy-MM-dd"))
  }
  const handleChange = () => {
    setIsImage2(!isImage2);
  };
  const fetchData = (dateFormated) => {
    getElszamolasbyDate(dateFormated).then((response) => {
      var decodedUser = jwt_decode(localStorage.getItem("accessToken"));
      if (decodedUser.priv === 1 || decodedUser.priv === 0) {
        setItems(response)
      } else {
        setItems(response.filter(obj => obj.name === decodedUser.name))
      }
    })
}
const generateUniqueFileName = (file, imageint) => {
  const timestamp = new Date().getTime();
  const uniqueName = `${timestamp}_${imageint}_${file.name}`;
  return uniqueName;
};
const handleSave = async (id) => {
    // Implement your save logic here, you can access the updated values by id
    const updatedItem = items.find((item) => item.id === id);
    if (updatedItem) {
      var decodedUser = jwt_decode(localStorage.getItem("accessToken"));
      var userName = decodedUser.name;
      if (updatedItem.name !== "" ) {
        userName = updatedItem.name;
      }
        try {
            if ((applyOperationsFromString(updatedItem.other, updatedItem.cash)-evaluateExpression(updatedItem.payment) < 0)) {
              window.alert("Nem maradhat minuszos készpénz!");
            } else {
              if(updatedItem.id !== undefined) {
                const formData = new FormData();
                formData.append('id', updatedItem.id);
                formData.append('name', userName);
                formData.append('location', updatedItem.location);
                formData.append('result', updatedItem.result);
                formData.append('card', updatedItem.card);
                formData.append('cash', updatedItem.cash);
                formData.append('payment', updatedItem.payment);
                formData.append('other', updatedItem.other);
                formData.append('comment', updatedItem.comment);
                formData.append('date', format(date, "yyyy-MM-dd"));
                await postElszamolas(formData);
                window.alert("Adat sikeresen elmentve");
              } else {
                if(isImage2 === false) {
                if (image1 && image2) {
                  
                  const formData = new FormData();
                formData.append('image1', image1, generateUniqueFileName(image1, 1));
                if (image2) formData.append('image2', image2, generateUniqueFileName(image2, 2));
                formData.append('name', userName);
                formData.append('location', updatedItem.location);
                formData.append('result', updatedItem.result);
                formData.append('card', updatedItem.card);
                formData.append('cash', updatedItem.cash);
                formData.append('payment', updatedItem.payment);
                formData.append('other', updatedItem.other);
                formData.append('comment', updatedItem.comment);
                formData.append('date', format(date, "yyyy-MM-dd", formData));
                    await postElszamolas(formData);
               window.alert("Adat sikeresen elmentve");
               fetchData(format(date, "yyyy-MM-dd"))
                } else {
                  window.alert("Nem töltöttél fel képeket!");
                }
              } else {
                if (image1) {
                const formData = new FormData();
                formData.append('image1', image1, generateUniqueFileName(image1, 1));
                formData.append('name', userName);
                formData.append('location', updatedItem.location);
                formData.append('result', updatedItem.result);
                formData.append('card', updatedItem.card);
                formData.append('cash', updatedItem.cash);
                formData.append('payment', updatedItem.payment);
                formData.append('other', updatedItem.other);
                formData.append('comment', updatedItem.comment);
                formData.append('date', format(date, "yyyy-MM-dd", formData));
                    await postElszamolas(formData);
  
               window.alert("Adat sikeresen elmentve");
               fetchData(format(date, "yyyy-MM-dd"))
              } else { window.alert("Nem töltöttél fel képet!");}}
             
              }
            }
            
        } catch (error) {
          window.alert("Nem töltöttél fel képeket!");
            console.error('Error uploading image:', error);
          }
    }
  };
  const handleDelete = async (id) => {
    if (window.confirm("Biztos törölni szeretnéd a sort?")) {
      try {
        await axios.delete(apiUrl + 'elszamolas.php?id='+ id);
        alert("Sor törölve!");
      fetchData(format(date, "yyyy-MM-dd"))
  } catch (error) {
      console.error('Error uploading image:', error);
    }
    } else {
    }
        
    };
    const compressAndLimitSize = async (file, maxSizeInMB, setImage) => {
      try {
        const options = {
          maxSizeMB: maxSizeInMB, // A maximális méret, amire tömöríteni szeretnénk (1 MB például)
          maxWidth: 1920,
          useWebWorker: true, // WebWorker használata a tömörítéshez (opcionális)
        };
    
        const compressedFile = await imageCompression(file, options);
        setImage(compressedFile);
      } catch (error) {
        console.error('Hiba a kép tömörítése közben:', error);
      }
    };
  function applyOperationsFromString(inputString, initialValue) {
    // Match both addition and subtraction operations along with numbers and optional parentheses
    const operationRegex = /([-+])\s*(\d+)\s*(?:\([^)]*\))?/g;
    let result = parseInt(initialValue);
    let match;
  
    while ((match = operationRegex.exec(inputString))) {
      const [_, operator, operandStr] = match;
      const operand = parseInt(operandStr);
  
      if (operator === '+') {
        result += operand;
      } else if (operator === '-') {
        result -= operand;
      } else {
        result += operand;
      }
    }
  
    return result;
  }
  function getResultsFromString(inputString) {
    const operationRegex = /([-+])\s*(\d+)\s*(?:\(([^)]*)\))?/g;
    let result = parseInt(0);
    let match;
  
    while ((match = operationRegex.exec(inputString))) {
        const [_, operator, operandStr, comment] = match;
        const operand = parseInt(operandStr);
        console.log(operand);
    
        if (operator === '+') {
          result += operand;
        } else if (operator === '-') {
          result -= operand;
        } else {
          result += operand;
        }
      }
  
    return result;
  }
  function evaluateExpression(expression) {
    // Split the expression into individual numbers based on the '+' sign
    console.log(expression);
    const numbers = expression.toLocaleString().split('+').map(Number);
  
    // Calculate the sum of the numbers
    const result = numbers.reduce((acc, num) => acc + num, 0);
  
    // Convert the result to an integer
    const integerResult = parseInt(result, 10);
  
    return integerResult;
  }
  const handleInputChange = (id, fieldName, value) => { 
    // Update the state with the new input value
    setItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id ? { ...item, [fieldName]: value } : item
      )
    );

    // Add the edited row to the editedRows array if it's not already there
    if (!editedRows.includes(id)) {
      setEditedRows([...editedRows, id]);
    }
  };

  const isRowEdited = (id) => {
    return editedRows.includes(id);
  };

  const addEmptyRow = () => {
    const emptyRow = {
      name: '',
      location: '',
      result: '',
      income: '',
      card: '',
      cash: '',
      payment: '',
      other: '',
      comment: '',
    };

    // Add the empty row at the beginning of the items array
    setItems([emptyRow, ...items]);
  };

  var decodedUser = jwt_decode(localStorage.getItem("accessToken"));
  return (
    <div>
        <h2>Napi elszámolás</h2>
        {decodedUser.priv === 2 && items.length < 1 && ( <button onClick={addEmptyRow}>Új sor hozzáadása</button> )}
        {decodedUser.priv === 1 && ( <button onClick={addEmptyRow}>Új sor hozzáadása</button> )}
        <DatePicker classname="datepicker" selected={date} onChange={(date) => setDataAndFetch(date)} />
      <Table>
      <Thead>
        <Tr className="thdesign">
          <Th>Név</Th>
          <Th>Helyszín</Th>
          <Th>Eredmény</Th>
          <Th>Kártyás bevétel</Th>
          <Th>Készpénzes bevétel</Th>
          <Th>Összbevétel (BK+KP)</Th>
          <Th>Fizetés</Th>
          <Th>Egyéb költség (+/-)</Th>
          <Th>Maradt pénz</Th>
          <Th>Megjegyzés</Th>
          <Th>Készpénz (bank automata)</Th>
          <Th>Csere / selejt fóliák</Th>
          <Th></Th>
        </Tr>
      </Thead>
      <Tbody>
      {items.map((val, key) => {
            return (
              <Tr key={val.id} className="trdesign">
                <Td>
                  <input
                    type="text"
                    disabled={decodedUser.priv === 2}
                    value={decodedUser.priv === 2 ? decodedUser.name : val.name}
                    style={{ maxWidth: '80px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                    onChange={(e) =>
                      handleInputChange(val.id, 'name', e.target.value)
                    }
                  />
                </Td>
                <Td>
                  <input
                    type="text"
                    value={val.location}
                    onChange={(e) =>
                      handleInputChange(val.id, 'location', e.target.value)
                    }
                    style={{ maxWidth: '80px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                  />
                </Td>
                <Td>
                  <input
                    type="text"
                    value={val.result}
                    onChange={(e) =>
                      handleInputChange(val.id, 'result', e.target.value)
                    }
                    style={{ maxWidth: '40px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                  />
                </Td>
                <Td>
                  <input
                    type="number"
                    value={val.card}
                    onChange={(e) =>
                      handleInputChange(val.id, 'card', e.target.value)
                    }
                    style={{ maxWidth: '70px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                  />
                </Td>
                <Td>
                  <input
                    type="number"
                    value={val.cash}
                    onChange={(e) =>
                      handleInputChange(val.id, 'cash', e.target.value)
                    }
                    style={{ maxWidth: '70px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                  />
                </Td>
                <Td>
                  <input
                    type="text"
                    value={(parseInt(val.cash) + parseInt(val.card)).toLocaleString("hu-HU", {
                      style: "currency",
                      currency: "HUF",
                      minimumFractionDigits: 0
                    })}

                    disabled
                    style={{ maxWidth: '70px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                  />
                </Td>
                
                <Td>
                  <input
                    type="text"
                    value={val.payment}
                    onChange={(e) =>
                      handleInputChange(val.id, 'payment', e.target.value)
                    }
                    style={{ maxWidth: '70px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                  />
                </Td>
                <Td>
                  <input
                    type="text"
                    value={val.other}
                    onChange={(e) =>
                      handleInputChange(val.id, 'other', e.target.value)
                    }
                  />
                </Td>
                <Td>
                  <input
                    type="text"
                    disabled
                    value={(applyOperationsFromString(val.other, val.cash)-val.payment).toLocaleString("hu-HU", {
                      style: "currency",
                      currency: "HUF",
                      minimumFractionDigits: 0
                    })}
                    onChange={(e) =>
                      handleInputChange(val.id, 'payment', e.target.value)
                    }
                    style={{ maxWidth: '60px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                  />
                </Td>
                
                <Td>
                  <input
                    type="text"
                    value={val.comment}
                    onChange={(e) =>
                      handleInputChange(val.id, 'comment', e.target.value)
                    }
                  />
                </Td>
                <Td className="minheight">
                {val.cashimagename && (<a
                      className="save-button"
                      href={apiUrl + val.cashimagename}
                    >
                      Megtekintés
                    </a>)}
                    {!val.cashimagename && (<input
                    type="file"
                    id="image1"
              onChange={(e) => compressAndLimitSize(e.target.files[0], 1, setImage1)}
              required
                  />)}
                </Td>
                <Td className="minheight">
                {!val.scrapimagename && (<div><input type="checkbox" id="scales" name="scales" checked={isImage2} onClick={handleChange} />
                <label for="scales">Nem volt csere/selejt</label><br></br></div>)}
                {val.scrapimagename == "elszamolas_images/" && (<p>
                      Nincs selejt
                    </p>)}
                {val.scrapimagename && val.scrapimagename != "elszamolas_images/" && (<a
                      className="save-button"
                      href={apiUrl + val.scrapimagename} >
                      Megtekintés
                    </a>)}
                    {!val.scrapimagename && !isImage2 && (<input
                    type="file"
                    id="image2"
              onChange={(e) => compressAndLimitSize(e.target.files[0], 1, setImage2)}
              required
                  />)}
                </Td>
                <Td className="minheight">
                  {isRowEdited(val.id) && (
                    <button
                      className="save-button"
                      onClick={() => handleSave(val.id)}
                    >
                      Mentés
                    </button>
                  )}
                  {decodedUser.priv === 1 && (<button
                      className="save-button"
                      onClick={() => handleDelete(val.id)}
                    >
                      Törlés
                    </button>)}
                </Td>
              </Tr>
            );
          })}
        <Tr className="trdesign">
                <Td className="minheight">
                  <p>Összesen</p>
                    
                </Td>
                <Td className="minheight">
                <p> </p>
                </Td>
                <Td className="minheight">
                <p>{items.reduce((acc, item) => acc + item.result, 0)} db</p>
                </Td>
                <Td className="minheight">
                <p>{items.reduce((acc, item) => acc + parseInt(item.card), 0).toLocaleString("hu-HU", {
  style: "currency",
  currency: "HUF",
  minimumFractionDigits: 0
})}</p>
                </Td>
                <Td className="minheight">
                <p>{items.reduce((acc, item) => acc + parseInt(item.cash), 0).toLocaleString("hu-HU", {
  style: "currency",
  currency: "HUF",
  minimumFractionDigits: 0
})}</p>
                </Td>
                <Td className="minheight">
                <p>{items.reduce((acc, item) => acc + parseInt(item.cash) + parseInt(item.card), 0).toLocaleString("hu-HU", {
  style: "currency",
  currency: "HUF",
  minimumFractionDigits: 0
})}</p>
                </Td>
                
                <Td className="minheight">
                <p>{items.reduce((acc, item) => acc + item.payment, 0).toLocaleString("hu-HU", {
  style: "currency",
  currency: "HUF",
  minimumFractionDigits: 0
})}</p>
                </Td>
                <Td className="minheight">
                <p>{items.reduce((acc, item) => acc + getResultsFromString(item.other), 0).toLocaleString("hu-HU", {
  style: "currency",
  currency: "HUF",
  minimumFractionDigits: 0
})}</p>
                </Td>
                <Td className="minheight">
                <p>{(items.reduce((acc, item) => acc + parseInt(item.cash), 0)-items.reduce((acc, item) => acc + evaluateExpression(item.payment), 0)-items.reduce((acc, item) => acc - getResultsFromString(item.other), 0)).toLocaleString("hu-HU", {
  style: "currency",
  currency: "HUF",
  minimumFractionDigits: 0
})}</p>
                </Td>
                
                <Td className="minheight">
                <p></p>
                </Td>
              </Tr>
      </Tbody>
      
    </Table>
      
    </div>
  );

}

