import React, { useState, useContext } from 'react';
import TypeOfItems from "../Items/product_types";
import AddPromoter from "../Promoter_reg/promoter_reg";
import RemovePromoter from "../Promoter_reg/promoter_remove";
import { ThemeContext } from './ThemeContext';


export default function SettingsPage() {
  const [typeOfPage, settypeOfPage] = useState("Promóter hozzáadása");
  const { theme } = useContext(ThemeContext);
  const primaryColor = theme.primary;

  const handleType = (e) => {
    const value = e.target.value;
    if (value === "Terméktípusok" || value === "Promóter hozzáadása" || value === "Promóter eltávolítása") {
      settypeOfPage(value);
    }
  };
  
  return(
    <div>
      <button className='typeButton' value="Promóter hozzáadása" onClick={(e) => handleType(e)}>Promóter hozzáadása</button>
      <button className='typeButton' value="Promóter eltávolítása" onClick={(e) => handleType(e)}>Promóter eltávolítása</button>
      <br />
      <h3>{typeOfPage}</h3>
      {typeOfPage === "Terméktípusok" ? <TypeOfItems /> : typeOfPage === "Promóter hozzáadása" ? <AddPromoter /> : <RemovePromoter />}
    </div>
  );
}
