// Form.js

import React, { useState, useRef, useContext } from 'react';
import axios from 'axios';
import './Garancia.css';
import jwt_decode from "jwt-decode";
import imageCompression from 'browser-image-compression';
import { PDFDocument, rgb, PNGImage } from 'pdf-lib';
import SignatureCanvas from 'react-signature-canvas';
import garancia50Pdf from './garancia50.pdf';
import garancia100Pdf from './garancia100.pdf';
import {getDocument} from 'pdfjs-dist/webpack';
import { ThemeContext } from '../App/ThemeContext';
import { apiUrl } from '../App/Constants';
import {
  postWarranty
} from "../../Services/Api/WarrantyApi";

const Form = () => {
  const { theme } = useContext(ThemeContext);
  const primaryColor = theme.primary;
  const [imei, setImei] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [image1, setImage1] = useState(null);
  const [image2, setImage2] = useState(null);
  const [image3, setImage3] = useState(null);
  const [image4, setImage4] = useState(null);
  const [image9, setImage9] = useState(null);
  const [image10, setImage10] = useState(null);
  const [selectedWarranty, setSelectedWarranty] = useState("");
  const signature1Ref = useRef();
  const signature2Ref = useRef();
  const clearSignature1 = () => {
    signature1Ref.current.clear();
  };
  const clearSignature2 = () => {
    signature2Ref.current.clear();
  };

  async function convertPdfToPng(pdfUrl) {
    try {
        const loadingTask = getDocument(pdfUrl);
        const pdf = await loadingTask.promise;
        const pngUrls = [];
        const page = await pdf.getPage(1);
        const viewport = page.getViewport({ scale: 1.0 });
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;
          // PNG kép készítése a lapról
        await page.render({
          canvasContext: context,
          viewport: viewport
        }).promise;
          // PNG kép URL-ének létrehozása a canvasról
        const pngUrl = canvas.toDataURL('image/png');
          // Hozzáadjuk a PNG URL-t a tömbhöz
        pngUrls.push(pngUrl);
        return pngUrls;
    } catch (error) {
        console.error('Hiba történt a PDF konvertálása során:', error);
        window.alert(error, error);
        return [];
    }
}
const displayPDF = () => {
  let pdfUrl = '';
  
  // Ellenőrizzük, melyik garancia opció lett kiválasztva
  if (selectedWarranty === 'garancia50') {
    pdfUrl = apiUrl + 'garancia50.pdf';
  } else if (selectedWarranty === 'garancia100') {
    pdfUrl = apiUrl + 'garancia100.pdf';
  } else {
    window.alert('Kérjük, válassz egy garancia opciót a PDF megtekintéséhez.');
    return;
  }

  // Megnyitjuk a kiválasztott PDF fájlt egy új böngészőablakban
  window.open(pdfUrl, '_blank');
};
function generateRandomName() {
  return 'SCR-' + Date.now() + '-' + Math.random().toString(36).substring(2, 8);
}

async function convertPngUrlsToBlobs(pngUrls) {
  try {
      const blobPromises = [];

      // Minden PNG URL-hez fetch-et hajtunk végre
      for (const pngUrl of pngUrls) {
          const response = await fetch(pngUrl);
          const blob = await response.blob();

          // Véletlenszerű név generálása
          const randomName = generateRandomName() + '.png';

          // Blob objektum létrehozása a megadott tulajdonságokkal
          const blobWithProperties = new Blob([blob], { type: 'image/png' });
          blobWithProperties.name = randomName;
          blobWithProperties.lastModified = Date.now();

          blobPromises.push(blobWithProperties);
      }

      // Várunk, amíg az összes blob létrejön
      const blobs = await Promise.all(blobPromises);

      return blobs;
  } catch (error) {
    window.alert('Hiba történt a blobok létrehozása során:', error);
      console.error('Hiba történt a blobok létrehozása során:', error);
      return [];
  }
}
  async function createPdfBlobWithInfo(signature1Ref, signature2Ref, date) {
    const pdfDoc = await addSignaturesAndDateToPDF(signature1Ref, signature2Ref, date);
    const pdfBytes = await pdfDoc.save();
    const uint8Array = new Uint8Array(pdfBytes);

    // A fájl betöltése
    convertPdfToPng(uint8Array)
    .then(pngUrls => {
        convertPngUrlsToBlobs(pngUrls)
    .then(blobs => {
        setImage10(blobs[0]);
    })
    .catch(error => {
        console.error('Hiba történt a blobok létrehozása során:', error);
        window.alert('Hiba történt a blobok létrehozása során:', error);
    });
    })
    .catch(error => {
        console.error('Hiba történt a PDF konvertálása során:', error);
        window.alert(error, error);
    });
  }
  const handlePDF = async () => {
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    createPdfBlobWithInfo(signature1Ref, signature2Ref, formattedDate);
    };

  const addSignaturesAndDateToPDF = async (signature1Ref, signature2Ref, date) => {
    const existingPdfBytes = selectedWarranty == "garancia50" ? await fetch(garancia50Pdf).then(res => res.arrayBuffer()) :  await fetch(garancia100Pdf).then(res => res.arrayBuffer());
    const bytes = new Uint8Array(existingPdfBytes);
    const pdfDoc = await PDFDocument.load(bytes);
    const page = pdfDoc.getPage(0);
    const signature1Image = await pdfDoc.embedPng(signature1Ref.current.toDataURL());
    const signature2Image = await pdfDoc.embedPng(signature2Ref.current.toDataURL());
    page.drawImage(signature1Image, {
        x: 100,
        y: 115,
        width: 100,
        height: 50,
    });
    page.drawImage(signature2Image, {
        x: 400,
        y: 115,
        width: 100,
        height: 50,
    });
    page.drawText(date, {
        x: 105,
        y: 165,
        size: 11,
        color: rgb(0, 0, 0),
    });
    return pdfDoc;
};

  const generateUniqueFileName = (file, imageint) => {
    const timestamp = new Date().getTime();
    const uniqueName = `${timestamp}_${imageint}_${file.name}`;
    return uniqueName;
  };
  const compressAndLimitSize = async (file, maxSizeInMB, setImage) => {
    try {
      const options = {
        maxSizeMB: maxSizeInMB, // A maximális méret, amire tömöríteni szeretnénk (1 MB például)
        maxWidthOrHeight: 800, // A kép maximális szélessége vagy magassága
        useWebWorker: true, // WebWorker használata a tömörítéshez (opcionális)
      };
  
      const compressedFile = await imageCompression(file, options);
      setImage(compressedFile);
    } catch (error) {
      window.alert('Hiba a kép tömörítése közben:', error);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
        await handlePDF();
    } catch (error) {
        window.alert('Hiba történt a PDF létrehozása során:', error);
        return;
    }

    // Form validation (add more validation as needed)
    const decoded = jwt_decode(localStorage.getItem("accessToken"));

    // `FormData` létrehozása
    const formData = new FormData();
    
    // Szöveges adatok hozzáadása a FormData-hoz
    formData.append('imei', imei);
    formData.append('name', name);
    formData.append('email', email);
    formData.append('webshopId', 0);
    formData.append('ischecked', 1);
    formData.append('userId', decoded.id);
    formData.append('type', selectedWarranty);

    // Fájlok hozzáadása, ha nem null értékűek
    if (image1) formData.append('image1', image1, generateUniqueFileName(image1, 1));
    if (image2) formData.append('image2', image2, generateUniqueFileName(image2, 2));
    if (image3) formData.append('image3', image3, generateUniqueFileName(image3, 3));
    if (image4) formData.append('image4', image4, generateUniqueFileName(image4, 4));
    if (image9) formData.append('image9', image9, generateUniqueFileName(image9, 9));
    if (image10) formData.append('image10', image10, generateUniqueFileName(image10, 10));

    // Garancia opció ellenőrzése
    if (selectedWarranty.length === 0) {
        window.alert('Válassz legalább 1 lehetőséget!');
        document.getElementById("errorMessage").style.display = "block";
        return;
    } else {
        if (window.confirm('Biztosan helyesek az adatok?')) {
            try {
                // `postWarranty` meghívása a `formData` objektummal
                const response = await postWarranty(formData);
                if (response) {
                    window.alert('Sikeresen felvitted a garanciális eszközt!');
                    window.location.reload();
                }
            } catch (error) {
                window.alert('Hiba történt: ' + error);
                console.error('Error saving data:', error);
            }
        }
    }
};
  return (
    <div className="container mt-4">
      <div className="form-container">
        <h3>Felvitel</h3>
        <form onSubmit={handleSubmit}>
            <div className="form-group">
            <label htmlFor="name" className="inputLabel">Név:</label>
            <br></br>
            <input
              type="name"
              className="mobileInput"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="imei" type="number" className="inputLabel">IMEI:</label>
            <br></br>
            <input
  type="text"
  className="mobileInput"
  id="imei"
  value={imei}
  onChange={(e) => setImei(e.target.value.replace(/\s/g, ''))}
  required
/>
          </div>
          <div className="form-group">
            <label htmlFor="email" className="inputLabel">Email:</label>
            <br></br>
            <input
              type="email"
              className="mobileInput"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
          <label className="inputLabel">Választott garancia</label>
          <p className='select2' id="errorMessage">Válassz legalább 1 lehetőséget!</p>
          <select className="select form-control" onChange={(e) => setSelectedWarranty(e.target.value)} required>
            <option value="">Válassz garanciát</option>
            <option value="garancia50">Garancia 50</option>
            <option value="garancia100">Garancia 100</option>
          </select>

<div className="checkbox-group">
  
</div>
</div>
            <div>
          <div className="form-group">
            <label htmlFor="image1" className="inputLabel">Előlap kép: sarok 1.</label>
            <input
              type="file"
              className="form-control"
              id="image1"
              onChange={(e) => compressAndLimitSize(e.target.files[0], 1, setImage1)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="image2" className="inputLabel">Előlap kép: sarok 2.</label>
            <input
              type="file"
              className="form-control"
              id="image2"
              
              onChange={(e) => compressAndLimitSize(e.target.files[0], 1, setImage2)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="image3" className="inputLabel">Előlap kép: sarok 3.</label>
            <input
              type="file"
              className="form-control"
              id="image3"
              onChange={(e) => compressAndLimitSize(e.target.files[0], 1, setImage3)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="image4" className="inputLabel">Előlap kép: sarok 4.</label>
            <input
              type="file"
              className="form-control"
              id="image4"
              onChange={(e) => compressAndLimitSize(e.target.files[0], 1, setImage4)}
              required
            />
          </div>
          </div>
        
          <div className="form-group">
            <label htmlFor="image9" className="inputLabel">Készülék IMEI + számla</label>
            <input
              type="file"
              className="form-control"
              id="image9"
              onChange={(e) => compressAndLimitSize(e.target.files[0], 1, setImage9)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="image10" className="inputLabel">Garanciális feltételek</label><br></br>
            <button style={{border: "2px solid", borderColor: primaryColor, color: primaryColor, backgroundColor: "white", borderRadius: "10px", fontSize: "12px"}} onClick={displayPDF}>Megtekintés</button>
          </div>
        </form>
        <hr></hr>
            <p className='inputLabel'>Promóter aláírása:</p>
            <div id="signatureDiv">
            <SignatureCanvas ref={signature1Ref} penColor="black" canvasProps={{ width: 300, height: 150 }} />
            </div>
            <button style={{border: "2px solid", borderColor: primaryColor, color: primaryColor, backgroundColor: "white", borderRadius: "10px", fontSize: "12px"}} onClick={clearSignature1}>Törlés</button>
            <hr></hr>
        <p className='inputLabel'>Vevő aláírása:</p>
        <div id="signatureDiv">
            <SignatureCanvas style={{borderRadius: "15px"}} ref={signature2Ref} penColor="black" canvasProps={{ width: 300, height: 150, borderRadius: "15px" }} />
            </div>
        <button  style={{border: "2px solid", borderColor: primaryColor, color: primaryColor, backgroundColor: "white", borderRadius: "10px", fontSize: "12px"}} onClick={clearSignature2}>Törlés</button>
        <hr></hr>
        <button  onClick={handleSubmit} type="submit" className='mobileInput submitbutton' style={{backgroundColor: primaryColor}}>Mentés</button>
      </div>
    </div>
  );
};

export default Form;
