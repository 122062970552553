import React, { useState, useEffect } from "react";
import { Scanner } from "@yudiel/react-qr-scanner";
import { updateInventory, fetchInventoryByBranch, updateRequiredQuantities } from "../../Services/Api/FranchiseInventoryApi";
import "./Raktarkeszlet.css";

export default function FranchiseRaktarkeszlet() {
  const [selectedLocation, setSelectedLocation] = useState("7");
  const [inventory, setInventory] = useState([]);
  const [requiredQuantities, setRequiredQuantities] = useState({});
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [productData, setProductData] = useState(null);
  const [neededQuantity, setNeededQuantity] = useState(0);
  const [showScanner, setShowScanner] = useState(false);
  const [requiredQuantitiesisOn, setRequiredQuantitiesisOn] = useState(false);

  useEffect(() => {
    setShowScanner(false);
    const loadInventory = async () => {
      if (selectedLocation) {
        const inventoryData = await fetchInventoryByBranch(selectedLocation);
        setRequiredQuantities(
          inventoryData.reduce((acc, item) => {
            acc[item.item_id] = {
              ...acc[item.item_id],
              [item.phonetype]: item.required_qty,
            };
            return acc;
          }))
        setInventory(inventoryData);
      }
    };
    loadInventory();
  }, [selectedLocation]);

  const handleRequiredQuantityChange = (itemId, subtype, value) => {
    setRequiredQuantities((prev) => ({
      ...prev,
      [itemId]: {
        ...prev[itemId],
        [subtype]: value,
      },
    }));
  };

  const handleSubmitRequiredQuantities = async () => {
    await updateRequiredQuantities(requiredQuantities, selectedLocation);
    alert("Szükséges készlet frissítve");
  };

  const handleSubmitQR = async () => {
    if (productData && neededQuantity > 0) {
      await updateInventory(selectedLocation, [
        {
          item_id: productData.item_id,
          subtype: productData.phonetype,
          quantity: neededQuantity,
        }
      ]);
  
      setIsPopupOpen(false);
      const inventoryData = await fetchInventoryByBranch(selectedLocation);
      setInventory(inventoryData);
      setShowScanner(true);
    } else {
      alert("A készlet már rendben van, nincs szükség feltöltésre.");
    }
  };

  const handleScan = (data) => {
    if (data) {
      const [productName, subtype] = data.includes("-") ? data.split(" - ") : [data, ""];
      const foundProduct = inventory.find(
        (item) =>
          item.item_name === productName &&
          (subtype === "" || item.phonetype === subtype)
      );

      if (foundProduct) {
        setProductData(foundProduct);
        const missingQuantity = foundProduct.required_qty - foundProduct.quantity;

        setNeededQuantity(missingQuantity > 0 ? missingQuantity : 0);

        setShowScanner(false);
        setIsPopupOpen(true);
      } else {
        alert("Termék nem található a listában.");
      }
    }
  };

  return (
    <div className="containerok">
      <h1>
        {requiredQuantitiesisOn
          ? "Franchise szükséges készlet kezelés"
          : "Franchise raktárkészlet kezelés"}
      </h1>
      <button
        onClick={() => setRequiredQuantitiesisOn(!requiredQuantitiesisOn)}
        className="qr-button"
      >
        {!requiredQuantitiesisOn ? "Szükséges készlet" : "Raktárkészlet"}
      </button>

      {!requiredQuantitiesisOn && selectedLocation !== "" && (
        <button
          onClick={() => setShowScanner((prev) => !prev)}
          className="qr-button"
        >
          {showScanner ? "QR-kód olvasó elrejtése" : "QR-kód olvasó"}
        </button>
      )}

      {showScanner && (
        <div className="qr-reader">
          <Scanner
            legacyMode={true}
            onScan={(result) => handleScan(result?.[0]?.rawValue)}
            onError={() => window.alert("Hibás QR kód")}
            constraints={{ facingMode: "environment" }}
            style={{ width: "100%" }}
          />
        </div>
      )}

      {isPopupOpen && productData && (
        <div className="popup">
          <h2>
            {productData.item_name}{" "}
            {productData.phonetype && ` - ${productData.phonetype}`}
          </h2>
          <p>Aktuális készlet: {productData.quantity}</p>
          <p>
            {neededQuantity > 0
              ? `${neededQuantity} db-ot kell feltölteni`
              : "A készlet rendben van."}
          </p>
          <button onClick={handleSubmitQR}>Kész</button>
          <button onClick={() => setIsPopupOpen(false)}>Mégse</button>
        </div>
      )}

      {inventory.length > 0 && (
        <div className="inventory-table-container">
          <table className="inventory-table">
            <thead>
              <tr>
                <th>Termék</th>
                <th>Típus</th>
                <th>Készlet</th>
                {requiredQuantitiesisOn && <th>Szükséges készlet</th>}
              </tr>
            </thead>
            <tbody>
              {inventory.map((item) => {
                const missingQuantity = item.required_qty - item.quantity;

                const tdStyle = {
                  backgroundColor:
                    missingQuantity > 0
                      ? "rgba(255, 99, 132, 0.2)"
                      : "rgba(75, 192, 192, 0.2)",
                  color: missingQuantity > 0 ? "red" : "green",
                  padding: "10px",
                  borderRadius: "4px",
                };

                return (
                  <tr key={`${item.item_id}-${item.phonetype}`}>
                    <td>{item.item_name}</td>
                    <td>{item.phonetype !== "-" ? item.phonetype : ""}</td>
                    <td>{item.quantity} db</td>
                    {requiredQuantitiesisOn && (
                      <td>
                        <input
                          type="number"
                          placeholder= {item.required_qty || 0}
                          value={
                            requiredQuantities[item.item_id]?.[
                              item.phonetype
                            ] || 0
                          }
                          onChange={(e) =>
                            handleRequiredQuantityChange(
                              item.item_id,
                              item.phonetype,
                              e.target.value
                            )
                          }
                          className="input-field"
                        />
                      </td>
                    )}
                    {!requiredQuantitiesisOn && (
                      <td style={tdStyle}>
                        {missingQuantity > 0
                          ? `${missingQuantity} db hiányzik`
                          : "Készlet rendben"}
                      </td>
                    )}
                    {!requiredQuantitiesisOn && (
                      <td>
                        {`${item.in_transit} db úton`}
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}

      {requiredQuantitiesisOn && (
        <button
          onClick={handleSubmitRequiredQuantities}
          className="submit-button"
        >
          Szükséges készlet frissítése
        </button>
      )}
    </div>
  );
}