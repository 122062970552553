import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import jwt_decode from "jwt-decode";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import {
  getEveryPromoter
} from "../../Services/Api/PromoterApi";
import {
  getTimeSheet
} from "../../Services/Api/TimeheetApi";

const isMobile = /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
const styles = {
    header: {
      backgroundColor: '#f2f2f2',
      padding: '8px',
      textAlign: 'left',
      fontWeight: 'bold',
      fontSize: '14px',
      textAlign: 'center',
      border: '1px solid #ddd',
    },
    evenRow: {
      backgroundColor: '#f9f9f9',
    },
    oddRow: {
      backgroundColor: '#ffffff',
    },
  };
      
      const rowStylesOrange = {
        fontSize: "15px",
        display: isMobile ? "block" : "flex",
        justifyContent: "space-between",
        padding: "20px",
        borderRadius: "10px",
        margin: "5px",
        alignItems: "center",
        backgroundColor: "#fdcb6e"
      };
      const rowStylesGrey = {
        fontSize: "15px",
        display: isMobile ? "block" : "flex",
        justifyContent: "space-between",
        padding: "20px",
        borderRadius: "10px",
        margin: "5px",
        alignItems: "center",
        backgroundColor: "grey"
      };
      const rowStylesRed = {
        fontSize: "15px",
        display: isMobile ? "block" : "flex",
        justifyContent: "space-between",
        padding: "20px",
        borderRadius: "10px",
        margin: "5px",
        alignItems: "center",
        backgroundColor: "#ff7675"
      };
      const rowStylesGreen = {
        fontSize: "15px",
        display: isMobile ? "block" : "flex",
        justifyContent: "space-between",
        padding: "20px",
        borderRadius: "10px",
        margin: "5px",
        alignItems: "center",
        backgroundColor: "#00b894",
        color: "white"
      };
      
      const columnStyles = {
        flexBasis: "10%",
      };
      const headerStyles = {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
        fontWeight: 'bold',
        padding: '10px',
        color: 'black',
        textAlign: 'center',
        fontSize: '14px'
      };
export default function WeeklyTimesheet() {

    const [allPromoters, setallPromoters] = useState([])
    const [allData, setAllData] = useState([])
    const [weekDays, setWeekdays] = useState([])
    const currentDate = new Date();
    const [currentYear, setCurrentYear] = useState(2024);
    const currentMonth = currentDate.getMonth() + 1;
      const [selectedWeek, setSelectedWeek] = useState('');
    useEffect(() => {
        if(selectedWeek != ""){
            fetchData();
            }
  }, [selectedWeek]);
  
    const fetchData = () => {
      if (localStorage.getItem("isUserLoggedIn")) {
        const startDateString = selectedWeek.split('(')[1].split('-')[0].replaceAll('.', '-').trim().replaceAll(' ', '');
        const droppedStartDateString = startDateString.substring(0, startDateString.length - 1)
        console.log(droppedStartDateString)
        const startDate1 = new Date(droppedStartDateString);
        const weekDays = [];
        const startDayOfWeek = startDate1.getDay() -1 ;
      
        for (let i = 0; i < 7; i++) {
          const nextDate = new Date(startDate1);
          nextDate.setDate(startDate1.getDate() + i - startDayOfWeek);
          weekDays.push(nextDate.toISOString().split('T')[0]);
        }
        setWeekdays(weekDays)
        getEveryPromoter().then((response) => {
          setallPromoters(response.filter(item => item.username !== "László Emma" && item.username !== "Zentai Zsófi" && item.username != "Máté").filter(item => item.isActive == 1))
        }).then(() => {
          getTimeSheet(droppedStartDateString.slice(0, -3)).then((data) => {
            setAllData(data)
          })
          })
          .catch(error => {
               window.location = '/login'
          })
      } else {
       window.location = '/login'
      }
  }
  const [data, setData] = useState([
    
  ]);
  

const currentWeekNumber = Math.ceil(
  (currentDate - new Date(currentYear, 0, 1)) / 604800000
);

// Hét lista létrehozása
const weekList = [];
for (let i = currentWeekNumber; i >= 1; i--) {
  const startDate = new Date(currentYear, 0, (i - 1) * 7 + 1);
  const endDate = new Date(currentYear, 0, i * 7);
  const weekLabel = `${i}.hét (${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()})`;
  weekList.push(weekLabel);
}

// Dropdown változáskezelő
const handleDropdownChange = (event) => {
  setSelectedWeek(event.target.value);
};
const handleYearChange = (event) => {
    setCurrentYear(event.target.value);
  };

  function geSummedDayData(data, date) {
    if (data.filter((item) => item.date == date).length != 0) {
    const workingPromoters = JSON.parse(data.filter((item) => item.date == date)[0].workingpromoters);
    return workingPromoters.length;
    }
    
}
function geSummedPromoterData(data, days, id) {
  let count = 0;
  data.forEach(item => {
    if (days.includes(item.date)) {
      console.log(days);
      console.log(item.date);
      const workingPromotersArray = JSON.parse(item.workingpromoters);
      count += workingPromotersArray.filter(promoterId => promoterId === id).length;
    }
  });
  return count;
}
function getPromoterData(promoterId, data, date) {
    if (data.filter((item) => item.date == date).length != 0) {
        const freePromoters = JSON.parse(data.filter((item) => item.date == date)[0].freepromoters);
        console.log(freePromoters)
    const workingPromoters = JSON.parse(data.filter((item) => item.date == date)[0].workingpromoters);
    if (freePromoters.includes(promoterId)) {
        return "❌";
    } else if (workingPromoters.includes(promoterId)) {
        return "✅";
    } else {
        return "";
    }
    }
    
}
  return(
    // Rendering the sorted table-like layout
<div id='masters'>
    <div>
        <a href='/idobeosztas' className='mastersButton no-print' >Havi beosztás</a>
        <h2 className='no-print'>Heti időbeosztás összefoglaló</h2>
        <h3 className='only-print'>{selectedWeek}</h3>
        <select value={currentYear} onChange={handleYearChange} className='no-print'>
        <option value={2023}>2023</option>
              <option value={2024}>2024</option>
            </select>
            <br></br>
        <select value={selectedWeek} onChange={handleDropdownChange} className='no-print'>
              <option value="">Válassz hetet...</option>
              {weekList.map((week, index) => (
                <option key={index} value={week}>
                  {week}
                </option>
              ))}
            </select>
            <br></br>
            {/* <button className='no-print' onClick={handlePrint} style={{ marginBottom: '10px' }}>Táblázat Nyomtatása</button> */}
            </div>

      <table style={{ borderCollapse: 'collapse', width: '100%' }}>
      <thead>
        <tr>
          <th style={styles.header}>Név</th>
          <th style={styles.header}>Hétfő</th>
          <th style={styles.header}>Kedd</th>
          <th style={styles.header}>Szerda</th>
          <th style={styles.header}>Csütörtök</th>
          <th style={styles.header}>Péntek</th>
          <th style={styles.header}>Szombat</th>
          <th style={styles.header}>Vasárnap</th>
          <th style={styles.header}>Összesen</th>
        </tr>
      </thead>
      <tbody>
        {allPromoters.map((person, index) => (
          <tr key={index} style={index % 2 === 0 ? styles.evenRow : styles.oddRow}>
            <td>{person.username}</td>
            <td>{getPromoterData(parseInt(person.id), allData, weekDays[0])}</td>
            <td>{getPromoterData(parseInt(person.id), allData, weekDays[1])}</td>
            <td>{getPromoterData(parseInt(person.id), allData, weekDays[2])}</td>
            <td>{getPromoterData(parseInt(person.id), allData, weekDays[3])}</td>
            <td>{getPromoterData(parseInt(person.id), allData, weekDays[4])}</td>
            <td>{getPromoterData(parseInt(person.id), allData, weekDays[5])}</td>
            <td>{getPromoterData(parseInt(person.id), allData, weekDays[6])}</td>
            <td>{geSummedPromoterData(allData, weekDays, parseInt(person.id))}</td>
          </tr>
        ))}
      </tbody>
      <thead>
        <tr>
          <th style={styles.header}>Összesen</th>
          <th style={styles.header}>{geSummedDayData(allData, weekDays[0])}</th>
          <th style={styles.header}>{geSummedDayData(allData, weekDays[1])}</th>
          <th style={styles.header}>{geSummedDayData(allData, weekDays[2])}</th>
          <th style={styles.header}>{geSummedDayData(allData, weekDays[3])}</th>
          <th style={styles.header}>{geSummedDayData(allData, weekDays[4])}</th>
          <th style={styles.header}>{geSummedDayData(allData, weekDays[5])}</th>
          <th style={styles.header}>{geSummedDayData(allData, weekDays[6])}</th>
          <th style={styles.header}>{geSummedDayData(allData, weekDays[0])+geSummedDayData(allData, weekDays[1])+geSummedDayData(allData, weekDays[2])+geSummedDayData(allData, weekDays[3])+geSummedDayData(allData, weekDays[4])+geSummedDayData(allData, weekDays[5])+geSummedDayData(allData, weekDays[6])}</th>
        </tr>
      </thead>
    </table>
</div>
  )

}


