import React, { useState, useEffect } from 'react';
import './promoter_reg.css';
import {
  getEveryPromoter,
  removePromoter
} from "../../Services/Api/PromoterApi";

export default function RemovePromoter() {
    const [items, setItems] = useState([])

    const fetchData = () => {
        getEveryPromoter().then((response) => {
            setItems(response.filter(item => item.isActive === 1))
          })
    }

    const handleRemove = user => {
        removePromoter(user.target.value).then(() => {
            fetchData()
        })
    }


    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div>
            {items.map((val, key) => {
                return (<div key={key}><p className='user'>{val.username}</p><button className='user buttonu' onClick={input => handleRemove(input)} value={val.id}>Eltávolítás</button></div>)
                
            })}
        </div>
    )
}